import Vue from "vue";
import VueRouter from "vue-router";
import Cgm from "../views/Cgm.vue";
import Aidex from "../views/Aidex.vue";
import AidexEu from "../views/AidexEu.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Cgm",
    component: Cgm,
  },
  {
    path: "/aidex",
    name: "Aidex",
    component: Aidex,
  },
  {
    path: "/zh/aidex",
    name: "Aidex",
    component: Aidex,
  },
  {
    path: "/en/aidex",
    name: "AidexEu",
    component: AidexEu,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
