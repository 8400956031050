import dayjs from "dayjs";
import {
  getAgpOptions,
  getGluOptions,
  getMiniGluOptions,
  getMultiGluChartOptions,
  getPentagonChartOptions,
  getAreaChartOptions,
  getDayPentagonChartOptions,
} from "@/util/chart";
export default {
  data() {
    return {
      baseInfo: {},
      startDate: "",
      endDate: "",
      startDateTime: "",
      endDateTime: "",
      serialNo: +new Date(),
      cgmRecord: {},
    };
  },
  methods: {
    isEmptyGlucose(index) {
      if (!this.cgmRecord.glucose || !this.cgmRecord.glucose[index]) {
        return false;
      }
      return this.cgmRecord.glucose[index].filter((g) => !isNaN(g)).length === 0
        ? true
        : false;
    },
    isShowGluPercent(index) {
      if (!this.cgmRecord.glucose || !this.cgmRecord.glucose[index]) {
        return false;
      }
      return this.cgmRecord.glucose[index].filter((g) => !isNaN(g)).length > 14
        ? true
        : false;
    },
    getToday(format) {
      format = format ? format : "YYYY-MM-DD HH:mm:ss";
      return dayjs().format(format);
    },
    getPercentStyle() {
      let hv = this.tirTimeRange.h.value;
      let nv = this.tirTimeRange.n.value;
      let lv = this.tirTimeRange.l.value;
      const filterZero = (val, height) => (val === 0 ? 0 : height);
      if (hv >= 96) {
        return {
          n: { height: filterZero(nv, lv === 0 ? "2%" : "4%") },
          l: { height: filterZero(lv, "2%") },
        };
      } else if (lv >= 96) {
        return {
          n: { height: filterZero(nv, hv === 0 ? "100%" : "98%") },
          l: { height: lv === 100 ? "100%" : "96%" },
        };
      } else if (Number(lv) + Number(nv) >= 96) {
        return {
          n: { height: filterZero(nv, hv === 0 ? "100%" : "98%") },
          l: { height: filterZero(lv, `${lv < 2 ? 2 : lv}%`) },
        };
      }
      return {
        n: {
          height: filterZero(nv, `${Number(nv) + Number(lv)}%`),
        },
        l: { height: filterZero(lv, `${lv < 2 ? 2 : lv}%`) },
      };
    },

    getPercentLabelStyle() {
      const _hv = this.tirTimeRange.h.value;
      const _nv = this.tirTimeRange.n.value;
      const _lv = this.tirTimeRange.l.value;
      const displayStyle = {
        h: _hv === 0 ? "none" : "block",
        n: _nv === 0 ? "none" : "block",
        l: _lv === 0 ? "none" : "block",
      };
      if (_hv >= 96) {
        return {
          h: { top: `${178 * 48 * 0.01 - 9}px`, display: displayStyle.h },
          n: {
            top: `${_lv === 0 ? 178 * 100 * 0.01 - 9 : 178 * 90 * 0.01 - 9}px`,
            display: displayStyle.n,
          },
          l: { top: `${178 * 100 * 0.01 - 9}px`, display: displayStyle.l },
        };
      } else if (_nv >= 96) {
        return {
          h: { top: `${178 * 1 * 0.01 - 9}px`, display: displayStyle.h },
          n: { top: `${178 * 50 * 0.01 - 9}px`, display: displayStyle.n },
          l: { top: `${178 * 100 * 0.01 - 9}px`, display: displayStyle.l },
        };
      } else if (_lv >= 96) {
        return {
          h: { top: `${178 * 0 * 0.01 - 9}px`, display: displayStyle.h },
          n: {
            top: `${_hv === 0 ? 178 * 0 * 0.01 - 9 : 178 * 10 * 0.01 - 9}px`,
            display: displayStyle.n,
          },
          l: { top: `${178 * 52 * 0.01 - 9}px`, display: displayStyle.l },
        };
      }
      let hv = 178 * _hv * 0.5 * 0.01 - 9;
      let nv = 178 * (Number(_nv) / 2 + Number(_hv)) * 0.01 - 9;
      let lv = 178 * (100 - Number(_lv) + Number(_lv) / 2) * 0.01 - 9;
      if (nv - hv < 10) {
        nv += 10;
      }
      if (lv - nv < 10) {
        nv -= 10;
      }
      return {
        h: {
          top: `${hv}px`,
          display: displayStyle.h,
        },
        n: {
          top: `${nv}px`,
          display: displayStyle.n,
        },
        l: {
          top: `${lv}px`,
          display: displayStyle.l,
        },
      };
    },

    formatterIndicator(value, isConvertUnit) {
      // return isConvertUnit
      //   ? (Number(value) / 18).toFixed(2)
      //   : Number(value).toFixed(2);
      if (isNaN(value)) {
        return "";
      }
      return Number.isInteger(Number(value))
        ? Number(value)
        : Number(value).toFixed(2);
    },
    dataFirstPage(data = []) {
      if (data.length === 0) {
        return 0;
      }
      return data[this.cgmRecord.glucose.length];
    },
    drawAgpChart() {
      const agpChart = this.$echarts.init(document.getElementById("agp"));
      agpChart.setOption(
        getAgpOptions(
          this.cgmRecord.dailyPrctile10,
          this.cgmRecord.dailyPrctile25,
          this.cgmRecord.dailyPrctile50,
          this.cgmRecord.dailyPrctile75,
          this.cgmRecord.dailyPrctile90
        )
      );
    },
    drawMiniGluChart() {
      this.cgmRecord.glucose.forEach((item, index) => {
        const gluChart = this.$echarts.init(
          document.getElementById(`glu_mini_chart_${index}`)
        );
        gluChart.setOption(
          getMiniGluOptions(item, index, this.cgmRecord.beginDate)
        );
      });
    },
    drawGluChart() {
      this.cgmRecord.glucose.forEach((item, index) => {
        const gluChart = this.$echarts.init(
          document.getElementById(`glu_chart_${index}`)
        );
        gluChart.setOption(
          getGluOptions(
            item,
            index,
            this.cgmRecord.beginDate,
            this.cgmRecord.eventMap
          )
        );
      });
    },
    drawMultiGluChart() {
      const multiChart = this.$echarts.init(
        document.getElementById("multi_glu")
      );
      multiChart.setOption(
        getMultiGluChartOptions(
          this.cgmRecord.glucose,
          this.cgmRecord.beginDate
        )
      );
    },
    drawPentagonChart() {
      const chart = this.$echarts.init(document.getElementById("pentagon"));
      chart.setOption(
        getPentagonChartOptions(this.dataFirstPage(this.cgmRecord.pentagon))
      );
    },
    drawAreaChart() {
      const chart = this.$echarts.init(document.getElementById("area"));
      chart.setOption(
        getAreaChartOptions(
          this.cgmRecord.pentagon,
          this.cgmRecord.beginDate,
          this.cgmRecord.glucose.length
        )
      );
    },
    drawDayPentagonChart() {
      this.cgmRecord.pentagon.forEach((item, index) => {
        if (
          index < this.cgmRecord.glucose.length &&
          !this.isEmptyGlucose(index)
        ) {
          const chart = this.$echarts.init(
            document.getElementById(`day_pentagon_chart_${index}`)
          );
          chart.setOption(getDayPentagonChartOptions(item));
        }
      });
    },
    dateFormatter(index, format) {
      if (!this.cgmRecord.beginDate || !this.cgmRecord.endDate) {
        return "";
      }
      return dayjs(this.cgmRecord.beginDate).add(index, "day").format(format);
    },
    bindGluItemChartId(index) {
      return "glu_chart_" + index;
    },
    bindMiniGluItemChartId(index) {
      return "glu_mini_chart_" + index;
    },
    bindPentagonId(index) {
      return "day_pentagon_chart_" + index;
    },
    getPentagonTableItem(index) {
      return [
        ["每日血糖评估", ""],
        ["葡萄糖水平在时间范围外", ""],
        ["变异系数", ""],
        ["低血糖强度", "mg/dl*min²"],
        ["高血糖强度", "mg/dl*min²"],
        ["平均葡萄糖值", "mg/dl"],
        ["面积", "mm²"],
      ][index];
    },
    getPentagonTableTdContent(pIndex, index) {
      const indexMapping = [
        "",
        "tor",
        "cv",
        "intHypo",
        "intHyper",
        "mbg",
        "area",
      ];
      let val = this.cgmRecord.pentagon
        .slice(0, this.cgmRecord.glucose.length)
        .map((c) => c[indexMapping[pIndex]])[index];
      if (isNaN(val) || this.isEmptyGlucose(index)) {
        return "";
      }
      val = Number.isInteger(val) || isNaN(val) ? val : val.toFixed(1);
      if (pIndex === 1) {
        const percent = ((val / 1440) * 100).toFixed();
        val = `${val}\n(${percent}%)`;
      }
      return val;
    },
    getDayDataOverview(index) {
      return [
        ["测试定次"],
        ["平均值(MBG，mmol/L)", "<6.6"],
        ["标准差(SDBG，mmol/L)", "<1.4"],
        ["变异系数(CV，％)", ""],
        ["葡萄糖最高值(mmol/L)", ""],
        ["葡萄糖最低值(mmol/L)", ""],
        ["葡萄糖≥13.9 mmol/L的百分比", ""],
        ["葡萄糖≥10.0 mmol/L的百分比", ""],
        ["葡萄糖≥7.8 mmol/L的百分比", "<4H(17%)"],
        ["葡萄糖≤3.9 mmol/L的百分比", "<3H(12%)"],
        ["葡萄糖≤2.8 mmol/L的百分比", ""],
        ["3.9mmol/L<葡萄糖<10 mmol/L的百分比", ""],
        ["TIR葡萄糖目标范围内百分比", ""],
      ][index];
    },
    getDayDataOverviewContent(pIndex, index) {
      if (pIndex === 0) {
        return this.formatterIndicator(this.cgmRecord.num[index]);
      } else if (!this.isShowGluPercent(index)) {
        return "";
      } else if (pIndex === 1) {
        return this.formatterIndicator(this.cgmRecord.mbg[index]);
      } else if (pIndex === 2) {
        return this.formatterIndicator(this.cgmRecord.sdbg[index]);
      } else if (pIndex === 3) {
        return this.formatterIndicator(this.cgmRecord.cv[index]);
      } else if (pIndex === 4) {
        return this.formatterIndicator(this.cgmRecord.maxbg[index]);
      } else if (pIndex === 5) {
        return this.formatterIndicator(this.cgmRecord.minbg[index]);
      } else if (pIndex === 6) {
        return this.formatterIndicator(
          this.cgmRecord.tir.tir.map((c) => c[7] + c[6])[index]
        );
      } else if (pIndex === 7) {
        return this.formatterIndicator(
          this.cgmRecord.tir.tir.map((c) => c[7] + c[6] + c[5])[index]
        );
      } else if (pIndex === 8) {
        return this.formatterIndicator(
          this.cgmRecord.tir.tir.map((c) => c[7] + c[6] + c[5] + c[4])[index]
        );
      } else if (pIndex === 9) {
        return this.formatterIndicator(
          this.cgmRecord.tir.tir.map((c) => c[0] + c[1] + c[2])[index]
        );
      } else if (pIndex === 10) {
        return this.formatterIndicator(
          this.cgmRecord.tir.tir.map((c) => c[0])[index]
        );
      } else if (pIndex === 11) {
        return this.formatterIndicator(
          this.cgmRecord.tir.tir.map((c) => c[3] + c[4])[index]
        );
      }
    },
    tirVal(arr) {
      let sum = 0;
      arr.forEach(
        (index) => (sum += this.dataFirstPage(this.cgmRecord.tir.tir)[index])
      );
      let _timeNoFormatter = 24 * sum * 0.01;
      let time = Number.isInteger(_timeNoFormatter)
        ? _timeNoFormatter
        : _timeNoFormatter.toFixed(1);
      let hour = Math.floor(time);
      let min = (time - hour) * 60;
      return {
        value: sum.toFixed(2),
        time: `${hour}小时${min}分`,
        hour: isNaN(time) ? "" : time,
      };
    },
    getEventMapByOffsetIndex(index) {
      if (
        !this.cgmRecord.eventMap ||
        Object.keys(this.cgmRecord.eventMap).length === 0
      ) {
        return [];
      }
      return (
        this.cgmRecord.eventMap[this.dateFormatter(index, "YYYY-MM-DD")].slice(
          0,
          10
        ) || []
      );
    },
    getEventLabel(item) {
      let unit = "mg";
      if (item.eventType === 1) {
        unit = "g";
      } else if (item.eventType === 2) {
        unit = "分钟";
      } else if (item.eventType === 3) {
        unit = "U";
      }
      return (
        item.eventTime.slice(11, 16) +
        " " +
        item.eventName +
        " " +
        item.eventData +
        unit
      );
    },
  },
  computed: {
    patientAge() {
      if (this.baseInfo.birthday) {
        return dayjs().diff(dayjs(this.baseInfo.birthday), "year");
      }
      return "--";
    },
    tirTimeRange() {
      if (!this.cgmRecord.tir || !this.cgmRecord.tir.tir) {
        return {
          h: { value: 0, time: 0 },
          n: { value: 0, time: 0 },
          l: { value: 0, time: 0 },
        };
      }
      const tirVal = this.dataFirstPage(this.cgmRecord.tir.tir);
      const hVal = tirVal[5] + tirVal[6] + tirVal[7];
      const nVal = tirVal[3] + tirVal[4];
      const lVal = tirVal[0] + tirVal[1] + tirVal[2];
      const hTime = 24 * hVal * 0.01;
      const nTime = 24 * nVal * 0.01;
      const lTime = 24 * lVal * 0.01;
      return {
        h: {
          value: Number.isInteger(hVal) ? hVal : hVal.toFixed(1),
          time: Number.isInteger(hTime) ? hTime : hTime.toFixed(1),
        },
        n: {
          value: Number.isInteger(nVal) ? nVal : nVal.toFixed(1),
          time: Number.isInteger(nTime) ? nTime : nTime.toFixed(1),
        },
        l: {
          value: Number.isInteger(lVal) ? lVal : lVal.toFixed(1),
          time: Number.isInteger(lTime) ? lTime : lTime.toFixed(1),
        },
      };
    },
    triangleStyle() {
      const obj = {
        width: 0,
        height: 0,
        "border-top": "20px solid transparent",

        "border-bottom": "20px solid transparent",
        position: "absolute",
        left: "60px",
        "z-index": 12,
      };
      const val = +this.formatterIndicator(this.cgmRecord.lbgi);
      if (val <= 1.1) {
        obj.top = "120px";
        obj["border-left"] = "10px solid #61a311";
      } else if (val <= 2.5) {
        obj.top = "80px";
        obj["border-left"] = "10px solid #1da9ed";
      } else if (val <= 5) {
        obj.top = "40px";
        obj["border-left"] = "10px solid #f38f20";
      } else {
        obj.top = "0px";
        obj["border-left"] = "10px solid #d61a00";
      }
      return obj;
    },
    lbgiHeight() {
      const val = +this.formatterIndicator(this.cgmRecord.lbgi);
      if (val <= 1.1) {
        return { top: "128px" };
      } else if (val <= 2.5) {
        return { top: "88px" };
      } else if (val <= 5) {
        return { top: "48px" };
      } else {
        return { top: "8px" };
      }
    },
  },
};
