import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import * as echarts from "echarts";
import { Loading, Message, Divider } from "element-ui";
import i18n from "./i18n";

Vue.config.productionTip = false;
Vue.prototype.$echarts = echarts;
Vue.use(Loading);
Vue.use(Divider);
Vue.prototype.$message = Message;
Vue.prototype.$loading = Loading.service;

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
