<template>
  <div class="panel">
    <div id="page-header">
      <img src="@/assets/jt_logo.svg" alt="" />
      <div class="info-one">
        <div class="title">持续葡萄糖监测报告</div>
        <div class="name-panel">
          <span>{{ baseInfo.name }}</span>
          <span style="margin-left: 20px"
            >住院号：{{ baseInfo.hospitalizedNo || "--" }}</span
          >
        </div>
      </div>
      <div class="divider"></div>
      <div class="info-two">
        <div class="left-info">
          <div>医院名称：{{ baseInfo.hospital || "--" }}</div>
          <div>CGM编号：{{ baseInfo.device || "--" }}</div>
        </div>
        <div class="right-info">
          <div>
            监测时间：{{ startDate }} - {{ endDate }}
            {{ cgmRecord.glucose.length }}天
          </div>
          <div>报告编号：{{ serialNo }}</div>
        </div>
      </div>
    </div>
    <div id="page-footer"></div>
    <table class="main-content">
      <thead>
        <tr>
          <td>
            <div class="page-header-space"></div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div class="container">
              <div class="header">
                <img src="@/assets/jt_logo.svg" alt="" />
                <div>持续葡萄糖监测报告</div>
              </div>
              <div class="tips">
                <div class="border"></div>
                <div class="label">基础信息</div>
              </div>
              <div class="base-info">
                <div class="info">
                  <div class="person-info">
                    <div class="info-item">
                      <div>报告编号：{{ serialNo }}</div>
                    </div>
                    <div class="info-item">
                      <div>报告时间：{{ getToday() }}</div>
                    </div>
                    <div class="info-item">
                      <div>医院名称：{{ baseInfo.hospital || "--" }}</div>
                    </div>
                    <div class="info-item">
                      <div>监测时间：{{ startDate }} - {{ endDate }}</div>
                    </div>
                    <div class="info-item">
                      <div>报告天数：{{ cgmRecord.glucose.length }}天</div>
                    </div>
                    <div class="info-item"></div>
                    <div class="info-item">
                      <div>姓名：{{ baseInfo.name || "--" }}</div>
                    </div>
                    <div class="info-item">
                      <div>
                        性别：{{ baseInfo.gender === "1" ? "男" : "女" }}
                      </div>
                    </div>
                    <div class="info-item">
                      <div>诊断：{{ baseInfo.diagnosis || "--" }}</div>
                    </div>
                    <div class="info-item">
                      <div>年龄：{{ patientAge || "--" }}</div>
                    </div>
                    <div class="info-item">
                      <div>手机号：{{ baseInfo.phone || "--" }}</div>
                    </div>
                    <div class="info-item">
                      <div>CGM编号：{{ baseInfo.device || "--" }}</div>
                    </div>
                    <div class="info-item">
                      <div>住院号：{{ baseInfo.hospitalizedNo || "--" }}</div>
                    </div>
                    <div class="info-item">
                      <div>床号：{{ baseInfo.bed || "--" }}</div>
                    </div>
                    <div class="info-item">
                      <div>科室：{{ baseInfo.inst_name || "--" }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tips">
                <div class="border"></div>
                <div class="label">血糖指标</div>
              </div>
              <div class="glu-info">
                <div class="info">
                  <div class="glu-data">
                    <div class="item-panel">
                      <div class="left">
                        <div class="item">
                          <div class="label-en">eHbA1c<sup>1</sup></div>
                          <div class="label-ch">预估糖化血红蛋白</div>
                          <div class="num">
                            {{ formatterIndicator(cgmRecord.ehba1c)
                            }}<span>%</span>
                            <arrow :value="cgmRecord.ehba1c" :lt="7" />
                          </div>
                          <div class="label-ref">参考值&lt;7%</div>
                        </div>
                        <div class="item">
                          <div class="label-en">MBG<sup>2</sup></div>
                          <div class="label-ch">平均葡萄糖值</div>
                          <div class="num">
                            {{
                              formatterIndicator(
                                dataFirstPage(cgmRecord.mbg),
                                true
                              )
                            }}<span>mmol/L</span>
                            <arrow
                              :value="dataFirstPage(cgmRecord.mbg)"
                              :gt="4.3"
                              :lt="6.6"
                            />
                          </div>
                          <div class="label-ref">参考值4.3-6.6mmol/L</div>
                        </div>
                      </div>
                      <div class="center">
                        <div class="item">
                          <div class="label-en">SDBG<sup>3</sup></div>
                          <div class="label-ch">葡萄糖标准差</div>
                          <div class="num">
                            {{
                              formatterIndicator(
                                dataFirstPage(cgmRecord.sdbg),
                                true
                              )
                            }}<span>mmol/L</span>
                            <arrow
                              :value="dataFirstPage(cgmRecord.sdbg)"
                              :gt="0.5"
                              :lt="1.4"
                            />
                          </div>
                          <div class="label-ref">参考值0.5-1.4mmol/L</div>
                        </div>
                        <div class="item">
                          <div class="label-en">CV<sup>4</sup></div>
                          <div class="label-ch">变异系数</div>
                          <div class="num">
                            {{ formatterIndicator(dataFirstPage(cgmRecord.cv))
                            }}<span>%</span>
                            <arrow
                              :value="dataFirstPage(cgmRecord.cv)"
                              :gt="18"
                              :lt="36"
                            />
                          </div>
                          <div class="label-ref">参考值18%-36%</div>
                        </div>
                      </div>
                      <div class="right">
                        <div class="item">
                          <div class="label-en">LAGE<sup>5</sup></div>
                          <div class="label-ch">最大血糖波动幅度</div>
                          <div class="num">
                            {{ formatterIndicator(cgmRecord.maxLage, true)
                            }}<span>mmol/L</span>
                            <arrow :value="cgmRecord.maxLage" :lt="5.7" />
                          </div>
                          <div class="label-ref">参考值&lt;5.7mmol/L</div>
                        </div>
                        <div class="item">
                          <div class="label-en">MAGE<sup>6</sup></div>
                          <div class="label-ch">平均血糖波动幅度</div>
                          <div class="num">
                            {{
                              formatterIndicator(
                                dataFirstPage(cgmRecord.mage)
                                  ? dataFirstPage(cgmRecord.mage).average
                                  : 0,
                                true
                              )
                            }}<span>mmol/L</span>
                            <arrow
                              :value="
                                dataFirstPage(cgmRecord.mage)
                                  ? dataFirstPage(cgmRecord.mage).average
                                  : 0
                              "
                              :lt="3.9"
                            />
                          </div>
                          <div class="label-ref">参考值&lt;3.9.mmol/L</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="glu-legend">
                    <div class="divider"></div>
                    <div class="label2">
                      TIR 葡萄糖目标范围内的百分比<sup>7</sup>
                    </div>
                    <div class="left-item">
                      <div class="yellow">
                        <div class="green" :style="getPercentStyle().n"></div>
                        <div class="red" :style="getPercentStyle().l"></div>
                        <div class="p1" :style="getPercentLabelStyle().h">
                          - 高{{ tirTimeRange.h.value }}%({{
                            tirTimeRange.h.time
                          }}H)
                        </div>
                        <div class="p2" :style="getPercentLabelStyle().n">
                          - 正常{{ tirTimeRange.n.value }}%({{
                            tirTimeRange.n.time
                          }}H)
                        </div>
                        <div class="p3" :style="getPercentLabelStyle().l">
                          - 低{{ tirTimeRange.l.value }}%({{
                            tirTimeRange.l.time
                          }}H)
                        </div>
                      </div>
                    </div>
                    <div class="right-item">
                      <div class="yellow-lg1">
                        <div class="yellow-block"></div>
                        <div class="tip-label">
                          <span>高≥10.0mmol/L </span><br /><span
                            >参考值（&lt;25%）</span
                          >
                        </div>
                      </div>
                      <div class="green-lg1">
                        <div class="green-block"></div>
                        <div class="tip-label">
                          <span>正常3.9-10.0mmol/L</span
                          ><span><br /></span>参考值（>70%）
                        </div>
                      </div>
                      <div class="red-lg1">
                        <div class="red-block"></div>
                        <div class="tip-label">
                          <span>低≤3.9mmol/L</span><br /><span
                            >参考值（&lt;4%）</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="glu-risk">
                    <div class="label">LBGI 低血糖风险<sup>8</sup></div>
                    <div class="red-panel">
                      <div class="blue-item"></div>
                      <div class="orange-item"></div>
                      <div class="green-item"></div>
                      <div :style="triangleStyle"></div>
                      <span :style="lbgiHeight">{{
                        formatterIndicator(cgmRecord.lbgi)
                      }}</span>
                    </div>
                    <div class="risk-indicator">
                      <div class="red-lg">
                        <div class="red-block"></div>
                        <div class="tip-label">
                          <span>高风险</span><br /><span>LBGI>5</span>
                        </div>
                      </div>
                      <div class="yellow-lg">
                        <div class="yellow-block"></div>
                        <div class="tip-label">
                          <span>中风险</span><br /><span>2.5&lt;LBGI≤5</span>
                        </div>
                      </div>
                      <div class="blue-lg">
                        <div class="green-block"></div>
                        <div class="tip-label">
                          <span>低风险</span><br /><span>1.1&lt;LBGI≤2.5</span>
                        </div>
                      </div>
                      <div class="green-lg">
                        <div class="green-block"></div>
                        <div class="tip-label">
                          <span>极低风险</span><br /><span>LBGI≤1.1</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="annotation">
                <div style="font-weight: 500">参数解释</div>
                <div>
                  1. eHbA1c预估糖化血红蛋白：即根据CGM
                  葡萄糖读数估算HbA1c，用于评价整体血糖水平，对大多数糖尿病患者，HbA1c控制目标值为＜7%。
                </div>
                <div>
                  2.
                  MBG平均葡萄糖值：CGM监测期间所有葡萄糖测定值的平均值，用于评价整体血糖水平，正常参考值4.3-6.6mmol/L。
                </div>
                <div>
                  3.
                  SDBG葡萄糖标准差：CGM监测期间测定值的标准差，用于评价总体偏离平均血糖值的程度，即血糖波动，正常参考值0.5-1.4mmol/L。
                </div>
                <div>
                  4.
                  CV变异系数：血糖标准差与平均血糖比值所得百分数，用于评价血糖波动，正常参考值18%-36%。
                </div>
                <div>
                  5.
                  LAGE最大血糖波动幅度：一天之内最大和最小血糖值之差，该指标可反映糖尿病患者日内单一的最大血糖波动，正常参考值&lt;5.7mmol/L。
                </div>
                <div>
                  6.
                  MAGE平均血糖波动幅度：CGM监测期间所有大于1SD的血糖波动幅度的平均值，正常参考值&lt;3.9mmol/L。
                </div>
                <div>
                  7.
                  TIR葡萄糖目标范围内百分比：狭义的TIR是指24h内葡萄糖在目标范围内(最常用的目标范围为3.9-10.0mmol/L)的时间或其所占的百分比；广义的TIR可包括葡萄糖处于不同范围内的时间，理论上也包括葡萄糖高于目标范围时间(TAR)和葡萄糖低于目标范围时间(TBR)。目前推荐大多数糖尿病患者的TIR控制目标为>70%，同时应强调控制目标的个体化。
                </div>
                <div>8. LBGI低血糖风险：用于准确评估严重低血糖的风险。</div>
              </div>
              <div class="tips">
                <div class="border"></div>
                <div class="label">其他血糖指标</div>
              </div>
              <div class="day-glu-info">
                <table border="0" cellspacing="0" cellpadding="4">
                  <tr>
                    <th>项目</th>
                    <th style="width: 55px">结果</th>
                    <th style="width: 110px">参考区间</th>
                    <th>指标说明</th>
                  </tr>
                  <tr>
                    <td>M-value M值</td>
                    <td>
                      <span>{{
                        formatterIndicator(dataFirstPage(cgmRecord.mvalue))
                      }}</span>
                      <arrow
                        :value="dataFirstPage(cgmRecord.mvalue)"
                        :gt="-0.01"
                        :lt="32.01"
                      />
                    </td>
                    <td>
                      <div>控制良好 0-18</div>
                      <div>控制一般 18-32</div>
                      <div>控制不佳 >32</div>
                    </td>
                    <td>
                      将所获得的血糖数据与血糖浓度参考值进行对数转换后的平均值，用于评估血糖波动的参考值
                    </td>
                  </tr>
                  <tr>
                    <td>J-index J系数</td>
                    <td>
                      <span>{{
                        formatterIndicator(dataFirstPage(cgmRecord.jindex))
                      }}</span>
                      <arrow
                        :value="dataFirstPage(cgmRecord.jindex)"
                        :gt="-0.01"
                        :lt="40.01"
                      />
                    </td>
                    <td>
                      <div>控制理想 10-20</div>
                      <div>控制良好 20-30</div>
                      <div>控制不佳 30-40</div>
                      <div>缺乏控制 >40</div>
                    </td>
                    <td>0.324×(MBG+SDBG)²，是描述血糖稳定性的参数</td>
                  </tr>
                  <tr>
                    <td>IQR(mmol/L)四分位距</td>
                    <td>
                      <span>{{
                        formatterIndicator(dataFirstPage(cgmRecord.iqr), true)
                      }}</span>
                      <arrow
                        :value="dataFirstPage(cgmRecord.iqr)"
                        :gt="0.7"
                        :lt="1.6"
                      />
                    </td>
                    <td>0.7-1.6</td>
                    <td>
                      是描述统计学中的一种方法，以确定第三四分位数和第一四分位数的区别。与方差、标准差一样，表示统计资料中各变量分散情形，但四分差更多为一种稳健统计
                    </td>
                  </tr>
                  <tr>
                    <td>AAC5.6(day*mmol/L)曲线上面积</td>
                    <td>
                      {{
                        formatterIndicator(dataFirstPage(cgmRecord.aac), true)
                      }}
                    </td>
                    <td></td>
                    <td>血糖5.6mmol/L的曲线上面积</td>
                  </tr>
                  <tr>
                    <td>AUC5.6(day*mmol/L)曲线下面积</td>
                    <td>
                      <span>{{
                        formatterIndicator(dataFirstPage(cgmRecord.auc), true)
                      }}</span>
                      <arrow :value="dataFirstPage(cgmRecord.auc)" :lt="0.9" />
                    </td>
                    <td>&lt;0.9</td>
                    <td>血糖5.6mmol/L的曲线下面积</td>
                  </tr>
                  <tr>
                    <td>HBGI高血糖指数</td>
                    <td>{{ formatterIndicator(cgmRecord.hbgi) }}</td>
                    <td></td>
                    <td>用于准确评估严重高血糖的风险</td>
                  </tr>
                  <tr>
                    <td>ADRR日均风险值</td>
                    <td>
                      <span>{{ formatterIndicator(cgmRecord.adrr) }}</span>
                      <arrow :value="cgmRecord.adrr" :gt="-0.01" :lt="40.01" />
                    </td>
                    <td>
                      <div>低风险 &lt;20</div>
                      <div>中等风险 20-40</div>
                      <div>高风险 &gt;40</div>
                    </td>
                    <td>
                      无论对于低血糖还是高血糖事件，ADRR都是最佳的预测方法，而且这种预测能力与糖尿病的分型无关
                    </td>
                  </tr>
                  <tr>
                    <td>GRADE血糖风险评估</td>
                    <td>
                      <span>{{
                        formatterIndicator(cgmRecord.grade.grade)
                      }}</span>
                      <arrow :value="cgmRecord.grade.grade" :lt="5" />
                    </td>
                    <td>&lt;5</td>
                    <td>
                      计算高血糖、正常血糖、低血糖对方程的权重，如果血糖偏离正常越远，其权重越大。它的优点是可以反映高血糖和低血糖的严重程度
                    </td>
                  </tr>
                  <tr>
                    <td>MAG(mmol/L/hour)平均绝对血糖改变值</td>
                    <td>
                      {{
                        formatterIndicator(dataFirstPage(cgmRecord.mag), true)
                      }}
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>MODD(mmol/L)日间血糖平均绝对差</td>
                    <td>
                      <span>{{
                        formatterIndicator(dataFirstPage(cgmRecord.modd), true)
                      }}</span>
                      <arrow :value="dataFirstPage(cgmRecord.modd)" :lt="5" />
                    </td>
                    <td>&lt;5</td>
                    <td>
                      连续2日内相对应测定值间相减所得差的绝对值的平均水平。评估日间血糖的波动程度，体现每日之间血糖的重复性
                    </td>
                  </tr>
                  <tr>
                    <td>CONGA4连续4小时血糖净作用</td>
                    <td>
                      {{ formatterIndicator(dataFirstPage(cgmRecord.conga4)) }}
                    </td>
                    <td></td>
                    <td>间隔4小时的血糖差值的标准差</td>
                  </tr>
                </table>
              </div>
              <div class="new-page"></div>
              <div class="tips">
                <div class="border"></div>
                <div class="label">AGP图谱</div>
              </div>
              <div class="agp-info">
                <div class="legend">
                  <div class="item">
                    <div class="b1"></div>
                    <div>50% 中位线</div>
                  </div>
                  <div class="item">
                    <div class="b2"></div>
                    <div>25%-75%区间</div>
                  </div>
                  <div class="item">
                    <div class="b3"></div>
                    <div>10%-90%区间</div>
                  </div>
                  <!-- <div class="item">
          <div class="b4"></div>
          <div>目标范围</div>
        </div> -->
                </div>
                <div id="agp"></div>
              </div>
              <div class="tips">
                <div class="border"></div>
                <div class="label">每日血糖简图</div>
              </div>
              <div class="mini-glu-panel">
                <div
                  class="mini-day-glu-item"
                  v-for="(item, index) in cgmRecord.glucose"
                  :key="index"
                >
                  <div class="date">
                    <div>{{ dateFormatter(index, "MM月DD日") }}</div>
                  </div>
                  <div class="chart" :id="bindMiniGluItemChartId(index)"></div>
                </div>
              </div>
              <div class="tips">
                <div class="border"></div>
                <div class="label">血糖五角模型</div>
              </div>
              <div class="pentagon-panel">
                <div class="average">
                  <div>多日平均值（{{ startDateTime }}-{{ endDateTime }}）</div>
                  <div id="pentagon"></div>
                </div>
                <div class="area">
                  <div>
                    多日面积柱形图（{{ startDateTime }}-{{ endDateTime }}）
                  </div>
                  <div id="area"></div>
                </div>
              </div>
              <div class="pentagon-table">
                <table border="0" cellspacing="0" cellpadding="4">
                  <tr>
                    <th style="width: 21%">日期</th>
                    <th v-for="(item, index) in cgmRecord.glucose" :key="index">
                      {{ dateFormatter(index, "MM/DD") }}
                    </th>
                  </tr>
                  <tr
                    v-for="(item, pIndex) in new Array(7).fill(0)"
                    :key="pIndex"
                  >
                    <td>
                      <span>{{ getPentagonTableItem(pIndex)[0] }}</span
                      ><br /><span>{{ getPentagonTableItem(pIndex)[1] }}</span>
                    </td>
                    <td v-for="(item, index) in cgmRecord.glucose" :key="index">
                      <div
                        v-if="pIndex === 0"
                        class="chart"
                        :id="bindPentagonId(index)"
                      ></div>
                      <span v-else>{{
                        getPentagonTableTdContent(pIndex, index)
                      }}</span>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="new-page"></div>
              <div class="tips" style="margin-bottom: 10px">
                <div class="border"></div>
                <div class="label">每日血糖曲线</div>
                <div class="comment">
                  每日血糖曲线中仅展示10个事件记录，超过部分不展示
                </div>
              </div>
              <div
                class="day-glu-item aviod-new-page"
                v-for="(item, index) in cgmRecord.glucose"
                :key="index"
              >
                <div class="chart-panel">
                  <div class="date">
                    <div>{{ dateFormatter(index, "MM月DD日") }}</div>
                  </div>
                  <div class="chart" :id="bindGluItemChartId(index)"></div>
                </div>
                <div class="event-panel">
                  <div
                    v-for="(item, index) in getEventMapByOffsetIndex(index)"
                    :key="index"
                  >
                    <img
                      v-if="item.eventType === 1"
                      src="@/assets/event1.png"
                      alt=""
                    />
                    <img
                      v-if="item.eventType === 2"
                      src="@/assets/event2.png"
                      alt=""
                    />
                    <img
                      v-if="item.eventType === 3"
                      src="@/assets/event3.png"
                      alt=""
                    />
                    <img
                      v-if="item.eventType === 4"
                      src="@/assets/event4.png"
                      alt=""
                    />
                    <span class="event-label">
                      {{ getEventLabel(item) }}
                    </span>
                  </div>
                </div>
              </div>
              <div
                :class="{
                  'new-page':
                    cgmRecord.glucose.length === 3 ||
                    cgmRecord.glucose.length === 4 ||
                    cgmRecord.glucose.length === 7 ||
                    cgmRecord.glucose.length === 8,
                }"
              ></div>
              <div class="tips">
                <div class="border"></div>
                <div class="label">多日血糖曲线</div>
              </div>
              <div id="multi_glu"></div>
              <div class="new-page"></div>
              <div class="header aviod-new-page">
                <img src="@/assets/jt_logo.svg" alt="" />
                <div>持续葡萄糖监测报告</div>
              </div>
              <div class="tips">
                <div class="border"></div>
                <div class="label">基础信息</div>
              </div>
              <div class="base-info">
                <div class="info">
                  <div class="person-info">
                    <div class="info-item">
                      <div>报告编号：{{ serialNo }}</div>
                    </div>
                    <div class="info-item">
                      <div>报告时间：{{ getToday() }}</div>
                    </div>
                    <div class="info-item">
                      <div>医院名称：{{ baseInfo.hospital || "--" }}</div>
                    </div>
                    <div class="info-item">
                      <div>监测时间：{{ startDate }} - {{ endDate }}</div>
                    </div>
                    <div class="info-item">
                      <div>报告天数：{{ cgmRecord.glucose.length }}天</div>
                    </div>
                    <div class="info-item"></div>
                    <div class="info-item">
                      <div>姓名：{{ baseInfo.name }}</div>
                    </div>
                    <div class="info-item">
                      <div>
                        性别：{{ baseInfo.gender === "1" ? "男" : "女" }}
                      </div>
                    </div>
                    <div class="info-item">
                      <div>诊断：{{ baseInfo.diagnosis || "--" }}</div>
                    </div>
                    <div class="info-item">
                      <div>年龄：{{ patientAge || "--" }}</div>
                    </div>
                    <div class="info-item">
                      <div>手机号：{{ baseInfo.phone || "--" }}</div>
                    </div>
                    <div class="info-item">
                      <div>CGM编号：{{ baseInfo.device || "--" }}</div>
                    </div>
                    <div class="info-item">
                      <div>住院号：{{ baseInfo.hospitalizedNo || "--" }}</div>
                    </div>
                    <div class="info-item">
                      <div>床号：{{ baseInfo.bed || "--" }}</div>
                    </div>
                    <div class="info-item">
                      <div>科室：{{ baseInfo.inst_name || "--" }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tips">
                <div class="border"></div>
                <div class="label">高低血糖事件</div>
                <div class="comment">
                  至少连续10分钟的测量值都在低血糖（高血糖）的范围内
                </div>
              </div>
              <div class="glu-event">
                <table border="0" cellspacing="0" cellpadding="4">
                  <tr>
                    <th>项目</th>
                    <th>&lt;2.8mmol/L</th>
                    <th>&lt;3.3mmol/L</th>
                    <th>&lt;3.9mmol/L</th>
                    <th>&gt;10mmol/L</th>
                    <th>&gt;13.9mmol/L</th>
                    <th>&gt;22.2mmol/L</th>
                  </tr>

                  <tr>
                    <td>平均每天小时数</td>
                    <td>{{ tirVal([0]).hour }}</td>
                    <td>{{ tirVal([0, 1]).hour }}</td>
                    <td>{{ tirVal([0, 1, 2]).hour }}</td>
                    <td>{{ tirVal([5, 6, 7]).hour }}</td>
                    <td>{{ tirVal([6, 7]).hour }}</td>
                    <td>{{ tirVal([7]).hour }}</td>
                  </tr>
                  <tr>
                    <td>平均每天事件数</td>
                    <td>
                      {{
                        formatterIndicator(
                          dataFirstPage(cgmRecord.lbgd28).times
                        )
                      }}
                    </td>
                    <td>
                      {{
                        formatterIndicator(
                          dataFirstPage(cgmRecord.lbgd33).times
                        )
                      }}
                    </td>
                    <td>
                      {{
                        formatterIndicator(
                          dataFirstPage(cgmRecord.lbgd39).times
                        )
                      }}
                    </td>
                    <td>
                      {{
                        formatterIndicator(
                          dataFirstPage(cgmRecord.lbgd100).times
                        )
                      }}
                    </td>
                    <td>
                      {{
                        formatterIndicator(
                          dataFirstPage(cgmRecord.lbgd139).times
                        )
                      }}
                    </td>
                    <td>
                      {{
                        formatterIndicator(
                          dataFirstPage(cgmRecord.lbgd222).times
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>平均每次持续时间（小时）</td>
                    <td>
                      {{
                        formatterIndicator(
                          dataFirstPage(cgmRecord.lbgd28).times
                        )
                      }}
                    </td>
                    <td>
                      {{
                        formatterIndicator(
                          dataFirstPage(cgmRecord.lbgd33).meanDuration
                        )
                      }}
                    </td>
                    <td>
                      {{
                        formatterIndicator(
                          dataFirstPage(cgmRecord.lbgd39).meanDuration
                        )
                      }}
                    </td>
                    <td>
                      {{
                        formatterIndicator(
                          dataFirstPage(cgmRecord.lbgd100).meanDuration
                        )
                      }}
                    </td>
                    <td>
                      {{
                        formatterIndicator(
                          dataFirstPage(cgmRecord.lbgd139).meanDuration
                        )
                      }}
                    </td>
                    <td>
                      {{
                        formatterIndicator(
                          dataFirstPage(cgmRecord.lbgd222).meanDuration
                        )
                      }}
                    </td>
                  </tr>
                </table>
              </div>
              <div class="tips">
                <div class="border"></div>
                <div class="label">每日数据统计</div>
              </div>
              <div class="glu-overview">
                <table border="0" cellspacing="0" cellpadding="4">
                  <tr>
                    <th>项目</th>
                    <th>正常参考值（24h）</th>
                    <th v-for="(item, index) in cgmRecord.glucose" :key="index">
                      {{ dateFormatter(index, "MM/DD") }}
                    </th>
                  </tr>
                  <tr
                    v-for="(item, pIndex) in new Array(13).fill(0)"
                    :key="pIndex"
                  >
                    <td style="width: 22%">
                      {{ getDayDataOverview(pIndex)[0] }}
                    </td>
                    <td style="width: 10%">
                      {{ getDayDataOverview(pIndex)[1] }}
                    </td>
                    <td
                      v-for="(item, index) in cgmRecord.glucose"
                      :key="index"
                      :class="{ 'bar-item': pIndex === 12 }"
                    >
                      <span v-if="pIndex < 12">{{
                        getDayDataOverviewContent(pIndex, index)
                      }}</span>
                      <div v-show="isShowGluPercent(index)" class="highBar">
                        <div
                          class="normalBar"
                          :style="{
                            height: getDayDataOverviewContent(11, index) + '%',
                          }"
                        ></div>
                        <div
                          class="lowBar"
                          :style="{
                            height: getDayDataOverviewContent(9, index) + '%',
                          }"
                        ></div>
                      </div>
                    </td>
                  </tr>
                </table>
                <div class="last-tip">
                  <div class="day-glu-overview">
                    <div class="p1">
                      <div class="block3"></div>
                      <div class="tip-label">高(>10.0mmol/L)</div>
                    </div>
                    <div class="p2">
                      <div class="block1"></div>
                      <div class="tip-label">正常(3.9-10.0mmol/L)</div>
                    </div>
                    <div class="p3">
                      <div class="block2"></div>
                      <div class="tip-label">低(＜3.9mmol/L)</div>
                    </div>
                  </div>
                  <div class="gist">
                    * 报告依据 《中国持续葡萄糖监测临床应用指南
                    2017年版》编制；TIR结果为基于所获得监测数据统计获得。<br />
                    CGM提示:共测定葡萄糖值{{
                      cgmRecord.glucose.length * 288
                    }}个，平均值{{
                      formatterIndicator(dataFirstPage(cgmRecord.mbg), true)
                    }}mmol/L，标准差{{
                      formatterIndicator(dataFirstPage(cgmRecord.sdbg), true)
                    }}mmol/L，变异系数{{
                      formatterIndicator(dataFirstPage(cgmRecord.cv))
                    }}%，最高值、最低值分别为{{
                      formatterIndicator(dataFirstPage(cgmRecord.maxbg))
                    }}mmol/L、{{
                      formatterIndicator(dataFirstPage(cgmRecord.minbg))
                    }}mmol/L。3.9mmol/L&lt;葡萄糖&lt;10mmol/L的百分比为{{
                      tirTimeRange.n.value
                    }}%。≥7.8mmol/L、≥10mmol/L及≥13.9mmol/L的时间(百分比)分别为{{
                      tirVal([4, 5, 6, 7]).value
                    }}%,{{ tirVal([5, 6, 7]).value }}%,{{
                      tirVal([6, 7]).value
                    }}%。≤3.9mmol/L及≤2.8mmol/L的时间及百分比分别为{{
                      tirVal([0, 1, 2]).time
                    }}({{ tirVal([0, 1, 2]).value }}%)，{{
                      tirVal([0]).time
                    }}({{ tirVal([0]).value }}%)。
                  </div>
                </div>
              </div>
              <div class="signature">
                <div>报告者：</div>
                <div class="line"></div>
                <div>审核者：</div>
                <div class="line"></div>
                <div>时间：{{ getToday("YYYY-MM-DD") }}</div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <div class="page-footer-space"></div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>
<script>
import { cgmApi } from "@/util/api";
import dayjs from "dayjs";
import arrow from "@/components/Arrow";
import mixins from "@/mixins";

export default {
  name: "Cgm",
  components: {
    arrow,
  },
  mixins: [mixins],
  mounted() {
    window.onbeforeprint = () => {
      window.history.pushState("", "", "/");
    };
    window.onafterprint = () => window.history.back();
    window.addEventListener("message", (res) => {
      if (res.data.type === "print") {
        window.print();
      }
    });
    if (window.navigator.userAgent.indexOf("Firefox") > -1) {
      const gluTable = document.getElementsByClassName("day-glu-info")[0];
      gluTable.setAttribute("style", "font-size:9px");
    }
    this.loadingInstance = this.$loading({ fullscreen: true });
    this.baseInfo = this.$route.query;
    if (!this.baseInfo.id || !this.baseInfo.start || !this.baseInfo.end) {
      this.$message.error("缺少查询参数");
      return;
    }
    if (this.baseInfo.name) {
      document.title = this.baseInfo.name + "的报告";
    }
    if (this.baseInfo.phone) {
      this.baseInfo.phone = this.baseInfo.phone.replace(
        /^(\d{3})\d{4}(\d{4})$/,
        "$1****$2"
      );
    }
    cgmApi
      .fetchCgmRecord({
        hyper: 10,
        hypo: 3.9,
        start_time: this.baseInfo.start,
        end_time: this.baseInfo.end,
        id: this.baseInfo.id,
      })
      .then((res) => {
        const { data, code, msg } = res.data;
        if (code !== 1) {
          this.$message.error(msg);
          return;
        }
        if (data && Object.keys(data).length !== 0) {
          this.cgmRecord = data;
          this.startDate = dayjs(this.cgmRecord.beginDate).format("YYYY/MM/DD");
          this.endDate = dayjs(this.cgmRecord.endDate).format("YYYY/MM/DD");
          this.startDateTime = dayjs(this.cgmRecord.beginDate).format("MM.DD");
          this.endDateTime = dayjs(this.cgmRecord.endDate).format("MM.DD");
        }
        this.$nextTick(() => {
          this.drawAgpChart();
          this.drawGluChart();
          this.drawMiniGluChart();
          this.drawMultiGluChart();
          this.drawPentagonChart();
          this.drawAreaChart();
          this.drawDayPentagonChart();
          this.loadingInstance.close();
        });
      });
  },
  data() {
    return {
      loadingInstance: null,
      baseInfo: {},
      startDate: "",
      endDate: "",
      startDateTime: "",
      endDateTime: "",
      serialNo: +new Date(),
      cgmRecord: {
        eventMap: {},
        mage: [],
        num: [],
        mbg: [],
        maxbg: [],
        minbg: [],
        sdbg: [],
        pentagon: [],
        grade: {
          grade: 0,
        },
        glucose: [],
        tir: {
          tir: [],
        },
        lbgd28: [],
        lbgd39: [],
        lbgd100: [],
        lbgd139: [],
        lbgd222: [],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
@page {
  size: A4;
  margin: 0 0 1cm 0;
}

.page-header-space {
  height: 2cm;
}
.page-footer-space {
  height: 0;
}

.panel {
  -webkit-print-color-adjust: exact;
  -moz-print-color-adjust: exact;
  color-adjust: exact;
}
.container {
  -webkit-print-color-adjust: exact;
  -moz-print-color-adjust: exact;
  color-adjust: exact;
  // box-shadow: 0 2px 12px 0 #d9d9d9;
  width: 21cm;
  min-height: 29.7cm;
  margin: 0 auto;
  border-radius: 2px;
  background-color: #fff;
  text-align: center;
  padding: 0 1cm;
  font-size: 14px;

  .header {
    color: #054617;
    font-size: 36px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      display: block;
      width: 45px;
      height: 45px;
    }
  }

  .new-page {
    page-break-before: always;
  }
  .aviod-new-page {
    page-break-inside: avoid;
    overflow: auto;
  }

  .tip-panel {
    margin: 30px 0;
    border-bottom: 1px solid #f0f0f0;
    position: relative;
    .label-panel {
      padding: 0 20px;
      line-height: 30px;
      background-color: #137eb2;
      border-radius: 50px;
      color: white;
      position: absolute;
      bottom: -15px;
      // left: 15px;
      font-size: 14px;
    }
  }

  .tips {
    page-break-inside: avoid;
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    line-height: 26px;
    position: relative;
    left: -22px;
    .border {
      width: 10px;
      margin-right: 10px;
      background-color: #054617;
    }
    .label {
      color: white;
      background-color: #054617;
      border-radius: 5px;
      padding: 0 20px;
    }
    .comment {
      margin-left: 10px;
      font-weight: 400;
    }
  }

  .base-info {
    .info {
      width: 100%;
      display: flex;
      flex-direction: row;
      .person-info {
        width: 100%;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        .info-item {
          width: 33%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          margin-top: 5px;
          font-size: 12px;
        }
      }
      .device-info {
        width: 50%;
        .device-tip {
          color: #1a9796;
          text-align: left;
        }
      }
    }
  }

  #multi_glu {
    width: 100%;
    zoom: 0.9;
    height: 320px;
  }

  .agp-info {
    #agp {
      width: 100%;
      height: 300px;
      zoom: 0.8;
    }
    .legend {
      display: flex;
      flex-direction: row;
      justify-content: center;
      .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 15px;

        .b1 {
          width: 30px;
          height: 2px;
          background-color: #05367f;
          margin-right: 5px;
        }
        .b2 {
          width: 30px;
          height: 15px;
          background-color: #7fa2d4;
          margin-right: 5px;
        }
        .b3 {
          width: 30px;
          height: 15px;
          background-color: #d2e1f2;
          margin-right: 5px;
        }
        .b4 {
          width: 30px;
          height: 8px;
          margin-right: 5px;
          border-top: 2px dashed #72b522;
          border-bottom: 2px dashed #72b522;
        }
      }
    }
  }
  .glu-info {
    margin-bottom: -35px;
    .info {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin: 10px 0;
      justify-content: flex-start;
      .glu-data {
        // zoom: 0.8;
        transform: scale(0.8);
        transform-origin: 0 0;
        position: relative;
        width: 50%;
        height: 200px;
        border: 1px solid #f0f0f0;
        background-color: #f5f5f5;
        border-radius: 10px;
        display: flex;
        flex-direction: row;

        .item-panel {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          flex-wrap: wrap;
          align-items: center;
          position: relative;
          .left {
            width: 33%;
            height: 100%;
          }
          .center {
            width: 33%;
            height: 100%;
            background-color: white;
          }
          .right {
            width: 33%;
            height: 100%;
          }
          .left .center .right {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
          }

          .item {
            height: 100px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .label-en {
              // font-size: 6pt;
              color: #426e06;
              font-weight: 600;
            }
            .label-ch {
              color: #747474;
              font-size: 12px;
            }
            .label-ref {
              font-size: 12px;
              color: #747474;
            }
            .num {
              font-size: 16px;
              // color: #747474;
              font-weight: 600;
              margin: 2px 0;
              span {
                color: black;
                font-size: 14px;
                margin-left: 5px;
                // font-weight: 500;
              }
            }
          }
        }
        .item-right-panel {
          width: 30%;
          display: flex;
          flex-direction: column;
          align-items: center;
          .label {
            color: #426e06;
            font-size: 12px;
            font-weight: 500;
            position: absolute;
          }
          .bar-contianer {
            height: 180px;
            width: 26px;
            margin-top: 10px;
            margin-left: 10px;
            border-radius: 13px;
            background-color: #eaeaea;
            position: relative;

            .p1 {
              position: absolute;
              left: -35px;
              top: 20px;
            }
            .p2 {
              position: absolute;
              left: -35px;
              top: 60px;
            }
            .p3 {
              position: absolute;
              left: -35px;
              top: 100px;
            }
            .p4 {
              position: absolute;
              left: -40px;
              top: 145px;
            }
          }
          .content-contianer {
            position: absolute;
            height: 100px;
            width: 26px;
            border-radius: 0 0 13px 13px;
            background-color: #d61a00;
            bottom: 0;
          }
        }
      }
      .glu-risk {
        width: 20%;
        position: relative;
        left: -60px;
        height: 200px;
        z-index: 1;
        height: 150px;
        .label {
          z-index: 10;
          position: absolute;
          left: 30px;
          top: -9px;
          font-weight: 500;
          font-size: 12px;
          color: #426e06;
        }
        .red-panel {
          // zoom: 0.7;
          transform: scale(0.8);
          transform: 0 0;
          width: 60px;
          height: 160px;
          background-color: #d61a00;
          position: absolute;
          top: 10px;
          left: -10px;
          z-index: 1;
          span {
            color: white;
            font-size: 18px;
            font-weight: 500;
            position: absolute;
            top: 8px;
            left: 12px;
            z-index: 12;
          }
        }
        .orange-item {
          width: 100%;
          height: 120px;
          background-color: #f38f20;
          position: absolute;
          bottom: 0;
          z-index: 2;
        }
        .blue-item {
          width: 100%;
          height: 80px;
          background-color: #1da9ed;
          position: absolute;
          bottom: 0;
          z-index: 3;
        }
        .green-item {
          width: 100%;
          height: 40px;
          background-color: #61a311;
          position: absolute;
          bottom: 0;
          z-index: 4;
        }
        .risk-indicator {
          // zoom: 0.7;
          transform: scale(0.8);
          transform: 0 0;
          width: 50%;
          height: 100%;
          position: relative;
          left: 100px;
          top: -10px;
          .tip-label {
            font-size: 12px;
            width: 120px;
            text-align: left;
          }
          .green-lg {
            position: absolute;
            display: flex;
            flex-direction: row;
            align-items: center;
            top: 150px;
            left: 20px;
            width: 190px;
            .green-block {
              width: 10px;
              height: 10px;
              margin-right: 10px;
              background-color: #61a311;
            }
          }
          .blue-lg {
            position: absolute;
            display: flex;
            flex-direction: row;
            align-items: center;
            top: 110px;
            left: 20px;
            width: 190px;
            .green-block {
              width: 10px;
              height: 10px;
              margin-right: 10px;
              background-color: #1da9ed;
            }
          }
          .yellow-lg {
            position: absolute;
            display: flex;
            flex-direction: row;
            align-items: center;
            top: 70px;
            left: 20px;
            width: 190px;
            .yellow-block {
              width: 10px;
              height: 10px;
              margin-right: 10px;
              background-color: #f38f20;
            }
          }
          .red-lg {
            position: absolute;
            display: flex;
            flex-direction: row;
            align-items: center;
            top: 30px;
            left: 20px;
            width: 190px;
            .red-block {
              width: 10px;
              height: 10px;
              margin-right: 10px;
              background-color: #d61a00;
            }
          }
        }
      }
      .glu-legend {
        position: relative;
        margin-left: -40px;
        width: 40%;
        display: flex;
        flex-direction: row;
        position: relative;

        .label2 {
          color: #426e06;
          font-size: 12px;
          font-weight: 500;
          position: absolute;
          left: 15px;
          top: -8px;
        }
        .left-item {
          // zoom: 0.7;
          transform: scale(0.75);
          transform-origin: 0 0;
          left: -20px;
          width: 50%;
          height: 100%;
          position: relative;
          top: -10px;
          .yellow {
            position: absolute;
            height: 180px;
            width: 26px;
            // border-radius: 13px;
            top: 45px;
            left: 0;
            background-color: #f38f20;
          }
          .green {
            position: absolute;
            width: 26px;
            // border-radius: 0 0 13px 13px;
            background-color: #61a311;
            bottom: 0;
          }
          .red {
            position: absolute;
            width: 26px;
            // border-radius: 0 0 13px 13px;
            background-color: #d61a00;
            bottom: 0;
          }
          .p1,
          .p2,
          .p3 {
            position: absolute;
            width: 200px;
            text-align: left;
            // color: #666666;
            font-size: 13px;
          }
          .p1 {
            top: 0px;
            left: 40px;
          }
          .p2 {
            top: 90px;
            left: 40px;
          }
          .p3 {
            top: 160px;
            left: 40px;
          }
        }
        .right-item {
          transform: scale(0.8);
          transform-origin: 0 0;
          width: 50%;
          height: 100%;
          position: relative;
          // top: -25px;
          left: -63px;
          top: -10px;
          .tip-label {
            font-size: 12px;
            width: 120px;
            text-align: left;
          }
          .yellow-lg1 {
            position: absolute;
            display: flex;
            flex-direction: row;
            align-items: center;
            top: 60px;
            left: 20px;
            width: 190px;
            .yellow-block {
              width: 10px;
              height: 10px;
              margin-right: 10px;
              background-color: #f38f20;
            }
          }
          .green-lg1 {
            position: absolute;
            display: flex;
            flex-direction: row;
            align-items: center;
            top: 120px;
            left: 20px;
            width: 190px;
            .green-block {
              width: 10px;
              height: 10px;
              margin-right: 10px;
              background-color: #61a311;
            }
          }
          .red-lg1 {
            position: absolute;
            display: flex;
            flex-direction: row;
            align-items: center;
            top: 180px;
            left: 20px;
            width: 190px;
            .red-block {
              width: 10px;
              height: 10px;
              margin-right: 10px;
              background-color: #d61a00;
            }
          }
        }
      }
    }
  }
  .day-glu-info {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    zoom: 0.9;
    font-size: 10px;
    table {
      width: 100%;
      margin-bottom: 10px;
      // border-color: #666666;
      // border: 1px solid #d1d1d1;
      th {
        background-color: #f0f0f0;
        font-size: 14px;
        font-weight: 400;
        border-right: 1px solid #d1d1d1;
        border-top: 1px solid #d1d1d1;
        &:first-child {
          border-left: 1px solid #d1d1d1;
        }
      }
      td {
        border-right: 1px solid #d1d1d1;
        border-bottom: 1px solid #d1d1d1;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(4) {
          text-align: left;
          padding: 5px 0 5px 10px;
        }
        &:first-child {
          border-left: 1px solid #d1d1d1;
        }
      }
      tr:nth-last-child(even) {
        background-color: #f0f0f0;
      }
      tr:last-child {
        td {
          border-bottom: 1px solid #d1d1d1;
        }
      }
      .ghost {
        border-top: none;
        border-bottom: none;
        background-color: white;
      }
    }
  }

  .pentagon-panel {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    font-size: 14px;
    margin-bottom: -100px;
    .average {
      font-weight: 600;
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      #pentagon {
        width: 100%;
        height: 300px;
        // zoom: 0.6;
        transform: scale(0.6);
        transform-origin: 0 0;
        position: relative;
        left: -5px;
      }
    }
    .area {
      width: 50%;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      align-items: center;
      > div:first-child {
        position: relative;
        right: 30px;
      }
      #area {
        width: 200%;
        height: 300px;
        // zoom: 0.6;
        transform: scale(0.6);
        transform-origin: 0 0;
        position: relative;
        left: 110px;
      }
    }
  }
  .day-pentagon {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    > div:first-child {
      width: 10%;
      display: flex;
      font-size: 12px;
      flex-direction: column;
      justify-content: center;
      font-weight: 500;
    }

    .day-pentagon-item {
      width: 9%;
      height: 60px;
      // margin: 10px 10px 0 0;
      // border: 1px solid #d9d9d9;
      position: relative;
      .chart {
        width: 100%;
        height: 100%;
      }
    }
  }
  .pentagon-table {
    width: 100%;
    zoom: 0.95;
    font-size: 11px;
    .chart {
      margin: 0 auto;
      width: 60px;
      height: 60px;
    }
    table {
      width: 100%;
      margin-bottom: 10px;
      th {
        // background-color: #f0f0f0;
        font-size: 14px;
        font-weight: 400;
        border-right: 1px solid #d1d1d1;
        border-bottom: 1px solid #d1d1d1;
        border-top: 1px solid #d1d1d1;
        &:first-child {
          border-left: 1px solid #d1d1d1;
        }
      }
      td {
        border-right: 1px solid #d1d1d1;
        border-bottom: 1px solid #d1d1d1;
        &:first-child {
          border-left: 1px solid #d1d1d1;
          > span:first-child {
            font-weight: 500;
          }
        }
      }
      tr:nth-last-child(even) {
        background-color: #f0f0f0;
      }
      tr:last-child {
        td {
          border-bottom: 1px solid #d1d1d1;
        }
      }
    }
  }
  .annotation {
    background-color: #f2f2f2;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 10px;
    font-size: 10px;
    border: 1px solid #d7d7d7;
    font-size: 11px;
    transform: scale(0.9);
    transform-origin: 0 0;
    width: 108%;
    margin-bottom: -20px;
    > div {
      line-height: 18px;
    }
  }
  .day-glu-overview {
    margin-top: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
    .block1 {
      width: 10px;
      height: 10px;
      background-color: #61a311;
      margin-right: 10px;
    }
    .block2 {
      width: 10px;
      height: 10px;
      background-color: #d61a00;
      margin-right: 10px;
    }
    .block3 {
      width: 10px;
      height: 10px;
      background-color: #f99500;
      margin-right: 10px;
    }

    .p1,
    .p2,
    .p3 {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 12px;
      text-align: left;
      margin-right: 20px;
    }
  }
  .mini-glu-panel {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    .mini-day-glu-item {
      width: 18.2%;
      height: 60px;
      border-radius: 10px;
      box-shadow: 0 2px 2px 0 #d9d9d9;
      margin: 10px 10px 0 0;
      border: 1px solid #d9d9d9;
      position: relative;
      .date {
        position: absolute;
        right: 3px;
        top: 0;
        font-size: 12px;
        z-index: 100;
      }
      .chart {
        width: 100%;
        height: 100%;
      }
    }
  }
  .day-glu-item {
    // zoom: 0.85;
    transform: scale(0.85);
    transform-origin: 0 0;
    height: 285px;
    width: 118%;
    display: flex;
    flex-direction: column;
    background-color: #f7f9fc;
    // margin: 5px 0;
    // margin-top: 20px;
    margin-bottom: -24px;
    .chart-panel {
      margin-top: 5px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      .date {
        height: 130px;
        width: 20px;
        // background-color: #054617;
        // color: white;
        border-radius: 15px;
        display: flex;
        align-items: center;
        margin-left: 15px;
        padding: 0 5px;
      }
      .chart {
        width: 100%;
        height: 200px;
      }
    }
    .event-panel {
      padding: 0 25px;
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      img {
        width: 20px;
        height: 20px;
        margin-right: 2px;
      }
      div {
        margin: 5px 0 5px 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .event-label {
          width: 140px;
          text-align: left;
          margin-left: 1px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .glu-event {
    margin-top: 10px;
    transform: scale(0.9);
    transform-origin: 0 0;
    width: 111%;
    margin-bottom: -10px;
    table {
      width: 100%;
      th {
        background-color: #f0f0f0;
        font-size: 14px;
        font-weight: 400;
        border-right: 1px solid #d1d1d1;
        border-bottom: 1px solid #d1d1d1;
        border-top: 1px solid #d1d1d1;
        &:first-child {
          border-left: 1px solid #d1d1d1;
        }
      }
      td {
        border-right: 1px solid #d1d1d1;
        border-bottom: 1px solid #d1d1d1;
        &:first-child {
          border-left: 1px solid #d1d1d1;
          text-align: left;
          > span:first-child {
            font-weight: 500;
          }
        }
      }
      tr:last-child {
        td {
          border-bottom: 1px solid #d1d1d1;
        }
      }
    }
  }
  .glu-overview {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: scale(0.9);
    transform-origin: 0 0;
    width: 111%;
    margin-bottom: -120px;
    table {
      width: 100%;
      // margin-bottom: 10px;
      // zoom: 0.9;
      th {
        // background-color: #f0f0f0;
        font-size: 14px;
        font-weight: 400;
        border-right: 1px solid #d1d1d1;
        border-bottom: 1px solid #d1d1d1;
        border-top: 1px solid #d1d1d1;
        &:first-child {
          border-left: 1px solid #d1d1d1;
        }
      }
      td {
        border-right: 1px solid #d1d1d1;
        border-bottom: 1px solid #d1d1d1;
        &:first-child {
          border-left: 1px solid #d1d1d1;
          text-align: left;
          > span:first-child {
            font-weight: 500;
          }
        }
      }
      tr:nth-last-child(odd) {
        background-color: #f0f0f0;
      }
      tr:last-child {
        td {
          border-bottom: 1px solid #d1d1d1;
        }
      }
      .bar-item {
        width: 25px;
        .highBar {
          height: 80px;
          max-width: 20px;
          background-color: #f99500;
          // border-radius: 10px;
          margin: 0 auto;
          position: relative;
          .normalBar {
            width: 100%;
            // border-radius: 0 0 10px 10px;
            height: 30%;
            position: absolute;
            bottom: 0;
            background-color: #61a311;
          }
          .lowBar {
            width: 100%;
            // border-radius: 0 0 10px 10px;
            height: 10%;
            position: absolute;
            bottom: 0;
            background-color: #d61a00;
          }
        }
      }
    }
  }

  .last-tip {
    display: flex;
    flex-direction: column;
    border: 1px solid #d1d1d1;
    padding: 10px;
    border-top: none;
    position: relative;
    top: -10px;
  }

  .gist {
    text-align: left;
    font-size: 12px;
    zoom: 0.9;
    margin-top: 20px;
  }

  .signature {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 3cm;
    .line {
      width: 3cm;
      border-bottom: 1px solid;
    }
  }

  .divider {
    // zoom: 0.7;
    height: 152px;
    width: 2px;
    border-left: 1px dashed;
    right: 83px;
    top: 10px;
    position: absolute;
  }

  ::v-deep .el-divider {
    height: 180px;
    right: 90px;
    top: 20px;
    position: absolute;
  }

  .right {
    left: 64%;
  }

  @media print {
    .base-info {
      zoom: 0.9;
    }
    .annotation {
      zoom: 0.9;
    }
  }
}

.main-content {
  margin: 0 auto;
  box-shadow: 0 2px 12px 0 #d9d9d9;
}

#page-header {
  position: fixed;
  height: 1.5cm;
  top: 0;
  width: 100%;
  border-bottom: 1px dashed #f2f2f2;
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 5px;
  img {
    display: block;
    width: 45px;
    height: 45px;
    margin-left: 40px;
  }
  .info-one {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
    .title {
      color: #4294bf;
      font-size: 24px;
    }
    .name-panel {
      font-size: 12px;
      display: inline-flex;
      justify-content: flex-start;
    }
  }
  .info-two {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    width: 50%;
    .left-info,
    .right-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      div {
        margin-top: 10px;
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
  .divider {
    height: 1.2cm;
    width: 2px;
    border-left: 1px dashed #f2f2f2;
    margin: 0 20px;
  }
}
#page-footer {
  position: fixed;
  height: 0;
  bottom: 0;
  width: 100%;
  display: none;
}

@media print {
  #page-header,
  #page-footer {
    display: flex;
  }
  body {
    counter-reset: page;
    background-color: #fff;
    margin: 0;
  }

  // #page-footer:after {
  //   counter-increment: page;
  //   content: "Page " counter(page);
  // }

  .main-content {
    box-shadow: none;
  }
}
</style>
