<template>
  <div class="panel">
    <div id="page-header">
      <div class="info-one">
        <img src="@/assets/aidex_eu_logo.svg" alt="" />
      </div>
      <div class="divider"></div>
      <div class="info-two">
        <div class="left-info">
          <div>{{ $t("NAME") }}:{{ baseInfo.name }}</div>
          <div>{{ $t("CGMSN") }}:{{ baseInfo.device }}</div>
        </div>
        <div class="right-info">
          <div>{{ $t("ReportID") }}:{{ serialNo }}</div>
          <div>
            {{ $t("AssessmentPeriod") }}:{{ startDate }} - {{ endDate }} ({{
              cgmRecord.glucose.length
            }}days)
          </div>
        </div>
      </div>
    </div>
    <div id="page-footer"></div>
    <table class="main-content">
      <thead>
        <tr>
          <td>
            <div class="page-header-space"></div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div class="container">
              <div class="header">
                <div>{{ $t("reportPage2Title") }}</div>
              </div>
              <div class="tips">
                <div class="border"></div>
                <div class="label">{{ $t("BasicInformation") }}</div>
              </div>
              <div class="base-info">
                <div class="info">
                  <div class="person-info">
                    <div class="info-item">
                      <div>{{ $t("NAME") }}:</div>
                      <div>{{ baseInfo.name }}</div>
                    </div>
                    <div class="info-item">
                      <div>{{ $t("GENDER") }}:</div>
                      <div>
                        {{ baseInfo.gender }}
                      </div>
                    </div>
                    <div class="info-item">
                      <div>{{ $t("AGE") }}:</div>
                      <div>{{ patientAge }}</div>
                    </div>
                    <div class="info-item">
                      <div>{{ $t("EMAIL") }}:</div>
                      <div>{{ baseInfo.email || "--" }}</div>
                    </div>
                    <div class="info-item">
                      <div>{{ $t("REPORTID") }}:</div>
                      <div>{{ serialNo }}</div>
                    </div>
                    <div class="info-item">
                      <div>{{ $t("ASSESSMENTPERIOD") }}:</div>
                      <div>
                        {{ startDate }} - {{ endDate }} ({{
                          cgmRecord.glucose.length
                        }}
                        days)
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tips">
                <div class="border"></div>
                <div class="label">{{ $t("BloodGlucoseMetrics") }}</div>
              </div>
              <div class="glu-info">
                <div class="info">
                  <div class="glu-data">
                    <div class="item-panel">
                      <div class="left">
                        <div class="item">
                          <div class="label-en">eHbA1c<sup>1</sup></div>
                          <div class="label-ch">
                            {{ $t("EstimatedGlycatedHemoglobin") }}
                          </div>
                          <div class="num">
                            {{ formatterIndicator(cgmRecord.ehba1c)
                            }}<span>%</span>
                            <arrow :value="cgmRecord.ehba1c" :lt="7" />
                          </div>
                          <div class="label-ref">
                            {{ $t("ReferenceValue") }}&lt;7%
                          </div>
                        </div>
                        <div class="item">
                          <div class="label-en">MBG<sup>2</sup></div>
                          <div class="label-ch">
                            {{ $t("MeanBloodGlucose") }}
                          </div>
                          <div class="num">
                            {{
                              formatterIndicator(
                                dataFirstPage(cgmRecord.mbg),
                                true
                              )
                            }}<span>mmol/L</span>
                            <arrow
                              :value="dataFirstPage(cgmRecord.mbg)"
                              :gt="4.3"
                              :lt="6.6"
                            />
                          </div>
                          <div class="label-ref">
                            {{ $t("ReferenceValue") }}4.3-6.6mmol/L
                          </div>
                        </div>
                      </div>
                      <div class="center">
                        <div class="item">
                          <div class="label-en">SDBG<sup>3</sup></div>
                          <div class="label-ch">
                            {{ $t("StandardDeviationofBloodGlucose") }}
                          </div>
                          <div class="num">
                            {{
                              formatterIndicator(
                                dataFirstPage(cgmRecord.sdbg),
                                true
                              )
                            }}<span>mmol/L</span>
                            <arrow
                              :value="dataFirstPage(cgmRecord.sdbg)"
                              :gt="0.5"
                              :lt="1.4"
                            />
                          </div>
                          <div class="label-ref">
                            {{ $t("ReferenceValue") }}0.5-1.4mmol/L
                          </div>
                        </div>
                        <div class="item">
                          <div class="label-en">CV<sup>4</sup></div>
                          <div class="label-ch">
                            {{ $t("CoefficientofVariation") }}
                          </div>
                          <div class="num">
                            {{ formatterIndicator(dataFirstPage(cgmRecord.cv))
                            }}<span>%</span>
                            <arrow
                              :value="dataFirstPage(cgmRecord.cv)"
                              :gt="18"
                              :lt="36"
                            />
                          </div>
                          <div class="label-ref">
                            {{ $t("ReferenceValue") }}18%-36%
                          </div>
                        </div>
                      </div>
                      <div class="right">
                        <div class="item">
                          <div class="label-en">LAGE<sup>5</sup></div>
                          <div class="label-ch">
                            {{ $t("LargestAmplitudeofGlycemicExcursion") }}
                          </div>
                          <div class="num">
                            {{ formatterIndicator(cgmRecord.maxLage, true)
                            }}<span>mmol/L</span>
                            <arrow :value="cgmRecord.maxLage" :lt="5.7" />
                          </div>
                          <div class="label-ref">
                            {{ $t("ReferenceValue") }}&lt;5.7mmol/L
                          </div>
                        </div>
                        <div class="item">
                          <div class="label-en">MAGE<sup>6</sup></div>
                          <div class="label-ch">
                            {{ $t("MeanAmplitudeofGlycemicExcursion") }}
                          </div>
                          <div class="num">
                            {{
                              formatterIndicator(
                                dataFirstPage(cgmRecord.mage)
                                  ? dataFirstPage(cgmRecord.mage).average
                                  : 0,
                                true
                              )
                            }}<span>mmol/L</span>
                            <arrow
                              :value="
                                dataFirstPage(cgmRecord.mage)
                                  ? dataFirstPage(cgmRecord.mage).average
                                  : 0
                              "
                              :lt="3.9"
                            />
                          </div>
                          <div class="label-ref">
                            {{ $t("ReferenceValue") }}&lt;3.9.mmol/L
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="glu-legend">
                    <div class="divider"></div>
                    <div class="label2">
                      {{ $t("TimeinRange") }}<sup>7</sup>
                    </div>
                    <div class="left-item">
                      <div class="yellow">
                        <div class="green" :style="getPercentStyle().n"></div>
                        <div class="red" :style="getPercentStyle().l"></div>
                        <div class="p1" :style="getPercentLabelStyle().h">
                          - {{ $t("High") }} {{ tirTimeRange.h.value }}%({{
                            tirTimeRange.h.time
                          }}H)
                        </div>
                        <div class="p2" :style="getPercentLabelStyle().n">
                          - {{ $t("Moderate") }} {{ tirTimeRange.n.value }}%({{
                            tirTimeRange.n.time
                          }}H)
                        </div>
                        <div class="p3" :style="getPercentLabelStyle().l">
                          - {{ $t("Low") }} {{ tirTimeRange.l.value }}%({{
                            tirTimeRange.l.time
                          }}H)
                        </div>
                      </div>
                    </div>
                    <div class="right-item">
                      <div class="yellow-lg1">
                        <div class="yellow-block"></div>
                        <div class="tip-label">
                          <span>{{ $t("High") }}≥10.0mmol/L </span><br /><span
                            >{{ $t("ReferenceValue") }}(&lt;25%)</span
                          >
                        </div>
                      </div>
                      <div class="green-lg1">
                        <div class="green-block"></div>
                        <div class="tip-label">
                          <span>{{ $t("Moderate") }}3.9-10.0mmol/L</span
                          ><span><br /></span>{{ $t("ReferenceValue") }}(>70%)
                        </div>
                      </div>
                      <div class="red-lg1">
                        <div class="red-block"></div>
                        <div class="tip-label">
                          <span>{{ $t("Low") }}≤3.9mmol/L</span><br /><span
                            >{{ $t("ReferenceValue") }}(&lt;4%)</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="glu-risk">
                    <div class="label">Low Blood Glucose Index<sup>8</sup></div>
                    <div class="red-panel">
                      <div class="blue-item"></div>
                      <div class="orange-item"></div>
                      <div class="green-item"></div>
                      <div :style="triangleStyle"></div>
                      <span :style="lbgiHeight">{{
                        formatterIndicator(cgmRecord.lbgi)
                      }}</span>
                    </div>
                    <div class="risk-indicator">
                      <div class="red-lg">
                        <div class="red-block"></div>
                        <div class="tip-label">
                          <span>{{ $t("HighRisk") }}</span
                          ><br /><span>LBGI>5</span>
                        </div>
                      </div>
                      <div class="yellow-lg">
                        <div class="yellow-block"></div>
                        <div class="tip-label">
                          <span>{{ $t("ModerateRisk") }}</span
                          ><br /><span>2.5&lt;LBGI≤5</span>
                        </div>
                      </div>
                      <div class="blue-lg">
                        <div class="green-block"></div>
                        <div class="tip-label">
                          <span>{{ $t("LowRisk") }}</span
                          ><br /><span>1.1&lt;LBGI≤2.5</span>
                        </div>
                      </div>
                      <div class="green-lg">
                        <div class="green-block"></div>
                        <div class="tip-label">
                          <span>{{ $t("VeryLowRisk") }}</span
                          ><br /><span>LBGI≤1.1</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="annotation">
                <div style="font-weight: 500">
                  {{ $t("MetricsExplaination") }}
                </div>
                <div>1. {{ $t("explaination1") }}</div>
                <div>
                  2.
                  {{ $t("explaination2") }}
                </div>
                <div>
                  3.
                  {{ $t("explaination3") }}
                </div>
                <div>
                  4.
                  {{ $t("explaination4") }}
                </div>
                <div>
                  5.
                  {{ $t("explaination5") }}
                </div>
                <div>
                  6.
                  {{ $t("explaination6") }}
                </div>
                <div>
                  7.
                  {{ $t("explaination7") }}
                </div>
                <div>8. {{ $t("explaination8") }}</div>
              </div>
              <div class="tips">
                <div class="border"></div>
                <div class="label">{{ $t("MoreBGMetrics") }}</div>
              </div>
              <div class="day-glu-info">
                <table border="0" cellspacing="0" cellpadding="4">
                  <tr>
                    <th>{{ $t("Metrics") }}</th>
                    <th style="width: 55px">{{ $t("Result") }}</th>
                    <th style="width: 110px">{{ $t("ReferenceValue") }}</th>
                    <th>{{ $t("MetricsExplaination") }}</th>
                  </tr>
                  <tr>
                    <td>M-value</td>
                    <td>
                      <span>{{
                        formatterIndicator(dataFirstPage(cgmRecord.mvalue))
                      }}</span>
                      <arrow
                        :value="dataFirstPage(cgmRecord.mvalue)"
                        :gt="-0.01"
                        :lt="32.01"
                      />
                    </td>
                    <td>
                      <div>{{ $t("GoodControl") }} 0-18</div>
                      <div>{{ $t("FairControl") }} 18-32</div>
                      <div>{{ $t("PoorControl") }} >32</div>
                    </td>
                    <td>
                      {{ $t("MvalueEx") }}
                    </td>
                  </tr>
                  <tr>
                    <td>J-index</td>
                    <td>
                      <span>{{
                        formatterIndicator(dataFirstPage(cgmRecord.jindex))
                      }}</span>
                      <arrow
                        :value="dataFirstPage(cgmRecord.jindex)"
                        :gt="-0.01"
                        :lt="40.01"
                      />
                    </td>
                    <td>
                      <div>{{ $t("VeryGoodControl") }} 10-20</div>
                      <div>{{ $t("GoodControl") }} 20-30</div>
                      <div>{{ $t("FairControl") }} 30-40</div>
                      <div>{{ $t("PoorControl") }} >40</div>
                    </td>
                    <td>{{ $t("JindexEx") }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("InterquartileRange") }}(mmol/L)</td>
                    <td>
                      <span>{{
                        formatterIndicator(dataFirstPage(cgmRecord.iqr), true)
                      }}</span>
                      <arrow
                        :value="dataFirstPage(cgmRecord.iqr)"
                        :gt="0.7"
                        :lt="1.6"
                      />
                    </td>
                    <td>0.7-1.6</td>
                    <td>
                      {{ $t("InterquartileRangeEx") }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("AreaaboveCurve") }}</td>
                    <td>
                      {{
                        formatterIndicator(dataFirstPage(cgmRecord.aac), true)
                      }}
                    </td>
                    <td></td>
                    <td>{{ $t("AreaaboveCurveEx") }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("AreaunderCurve") }}</td>
                    <td>
                      <span>{{
                        formatterIndicator(dataFirstPage(cgmRecord.auc), true)
                      }}</span>
                      <arrow :value="dataFirstPage(cgmRecord.auc)" :lt="0.9" />
                    </td>
                    <td>&lt;0.9</td>
                    <td>{{ $t("AreaunderCurveEx") }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("HighBloodGlucoseIndex") }}</td>
                    <td>{{ formatterIndicator(cgmRecord.hbgi) }}</td>
                    <td></td>
                    <td>{{ $t("HighBloodGlucoseIndexEx") }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("AverageDailyRiskRange") }}</td>
                    <td>
                      <span>{{ formatterIndicator(cgmRecord.adrr) }}</span>
                      <arrow :value="cgmRecord.adrr" :gt="-0.01" :lt="40.01" />
                    </td>
                    <td>
                      <div>{{ $t("LowRisk") }} &lt;20</div>
                      <div>{{ $t("ModerateRisk") }} 20-40</div>
                      <div>{{ $t("HighRisk") }} &gt;40</div>
                    </td>
                    <td>
                      {{ $t("AverageDailyRiskRangeEx") }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("GlycemicRiskAssessmentDiabetesEquation") }}</td>
                    <td>
                      <span>{{
                        formatterIndicator(cgmRecord.grade.grade)
                      }}</span>
                      <arrow :value="cgmRecord.grade.grade" :lt="5" />
                    </td>
                    <td>&lt;5</td>
                    <td>
                      {{ $t("GlycemicRiskAssessmentDiabetesEquationEx") }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ $t("MeanAmplitudeofGlycemicExcursion") }}(mmol/L/hour)
                    </td>
                    <td>
                      {{
                        formatterIndicator(dataFirstPage(cgmRecord.mag), true)
                      }}
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>{{ $t("MeanofDailyDifference") }}(mmol/L)</td>
                    <td>
                      <span>{{
                        formatterIndicator(dataFirstPage(cgmRecord.modd), true)
                      }}</span>
                      <arrow :value="dataFirstPage(cgmRecord.modd)" :lt="5" />
                    </td>
                    <td>&lt;5</td>
                    <td>
                      {{ $t("MeanofDailyDifferenceEx") }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("ContinuousOverlappingNetGlycemicAction") }}</td>
                    <td>
                      {{ formatterIndicator(dataFirstPage(cgmRecord.conga4)) }}
                    </td>
                    <td></td>
                    <td>
                      {{ $t("ContinuousOverlappingNetGlycemicActionEx") }}
                    </td>
                  </tr>
                </table>
              </div>
              <div class="new-page"></div>
              <div class="tips">
                <div class="border"></div>
                <div class="label">{{ $t("AGPProfile") }}</div>
              </div>
              <div class="agp-info">
                <div class="legend">
                  <div class="item">
                    <div class="b1"></div>
                    <div>50% {{ $t("MedianLine") }}</div>
                  </div>
                  <div class="item">
                    <div class="b2"></div>
                    <div>25%-75% {{ $t("Interval") }}</div>
                  </div>
                  <div class="item">
                    <div class="b3"></div>
                    <div>10%-90% {{ $t("Interval") }}</div>
                  </div>
                  <!-- <div class="item">
          <div class="b4"></div>
          <div>目标范围</div>
        </div> -->
                </div>
                <div id="agp"></div>
              </div>
              <div class="tips">
                <div class="border"></div>
                <div class="label">{{ $t("DailyBGCurve") }}</div>
              </div>
              <div class="mini-glu-panel">
                <div
                  class="mini-day-glu-item"
                  v-for="(item, index) in cgmRecord.glucose"
                  :key="index"
                >
                  <div class="date">
                    <div>{{ dateFormatter(index, "MM/DD") }}</div>
                  </div>
                  <div class="chart" :id="bindMiniGluItemChartId(index)"></div>
                </div>
              </div>
              <div class="tips">
                <div class="border"></div>
                <div class="label">
                  {{ $t("BGPentagonalModel") }}
                </div>
              </div>
              <div class="pentagon-panel">
                <div class="average">
                  {{ $t("MultidayAverageValue") }}({{ startDateTime }}-{{
                    endDateTime
                  }})
                  <div id="pentagon"></div>
                </div>
                <div class="area">
                  <div>
                    {{ $t("MultidayStackedBarChart") }}({{ startDateTime }}-{{
                      endDateTime
                    }})
                  </div>
                  <div id="area"></div>
                </div>
              </div>
              <div class="pentagon-table">
                <table border="0" cellspacing="0" cellpadding="4">
                  <tr>
                    <th style="width: 21%">{{ $t("Date") }}</th>
                    <th v-for="(item, index) in cgmRecord.glucose" :key="index">
                      {{ dateFormatter(index, "MM/DD") }}
                    </th>
                  </tr>
                  <tr
                    v-for="(item, pIndex) in new Array(7).fill(0)"
                    :key="pIndex"
                  >
                    <td>
                      <span>{{ getPentagonTableItem(pIndex)[0] }}</span
                      ><br /><span>{{ getPentagonTableItem(pIndex)[1] }}</span>
                    </td>
                    <td v-for="(item, index) in cgmRecord.glucose" :key="index">
                      <div
                        v-if="pIndex === 0"
                        class="chart"
                        :id="bindPentagonId(index)"
                      ></div>
                      <span v-else>{{
                        getPentagonTableTdContent(pIndex, index)
                      }}</span>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="new-page"></div>
              <div class="tips" style="margin-bottom: 10px">
                <div class="border"></div>
                <div class="label">{{ $t("DailyBGLog") }}</div>
                <div class="comment">
                  {{ $t("DailyBGLogEx") }}
                </div>
              </div>
              <div
                class="day-glu-item aviod-new-page"
                v-for="(item, index) in cgmRecord.glucose"
                :key="index"
              >
                <div class="chart-panel">
                  <div class="date">
                    <div>{{ enDateFormatter(index, "M/D")[0] }}</div>
                    <div style="height: 10px"></div>
                    <div>{{ enDateFormatter(index, "M/D")[1] }}</div>
                  </div>
                  <div class="chart" :id="bindGluItemChartId(index)"></div>
                </div>
                <div class="event-panel">
                  <div
                    v-for="(item, index) in getEventMapByOffsetIndex(index)"
                    :key="index"
                  >
                    <img
                      v-if="item.eventType === 1"
                      src="@/assets/event1.png"
                      alt=""
                    />
                    <img
                      v-if="item.eventType === 2"
                      src="@/assets/event2.png"
                      alt=""
                    />
                    <img
                      v-if="item.eventType === 3"
                      src="@/assets/event3.png"
                      alt=""
                    />
                    <img
                      v-if="item.eventType === 4"
                      src="@/assets/event4.png"
                      alt=""
                    />
                    <span class="event-label">
                      {{ getEventLabel(item) }}
                    </span>
                  </div>
                </div>
              </div>
              <div
                :class="{
                  'new-page':
                    cgmRecord.glucose.length === 3 ||
                    cgmRecord.glucose.length === 4 ||
                    cgmRecord.glucose.length === 7 ||
                    cgmRecord.glucose.length === 8,
                }"
              ></div>
              <div class="tips">
                <div class="border"></div>
                <div class="label">{{ $t("MultiBGData") }}</div>
              </div>
              <div id="multi_glu"></div>
              <div class="new-page"></div>
              <div class="header aviod-new-page">
                <div>{{ $t("reportPage2Title") }}</div>
              </div>
              <div class="tips">
                <div class="border"></div>
                <div class="label">{{ $t("BasicInformation") }}</div>
              </div>
              <div class="base-info">
                <div class="info">
                  <div class="person-info">
                    <div class="info-item">
                      <div>{{ $t("NAME") }}:</div>
                      <div>{{ baseInfo.name }}</div>
                    </div>
                    <div class="info-item">
                      <div>{{ $t("GENDER") }}:</div>
                      <div>
                        {{ baseInfo.gender }}
                      </div>
                    </div>
                    <div class="info-item">
                      <div>{{ $t("AGE") }}:</div>
                      <div>{{ patientAge }}</div>
                    </div>
                    <div class="info-item">
                      <div>{{ $t("EMAIL") }}:</div>
                      <div>{{ baseInfo.email || "--" }}</div>
                    </div>
                    <div class="info-item">
                      <div>{{ $t("REPORTID") }}:</div>
                      <div>{{ serialNo }}</div>
                    </div>
                    <div class="info-item">
                      <div>{{ $t("ASSESSMENTPERIOD") }}:</div>
                      <div>
                        {{ startDate }} - {{ endDate }} ({{
                          cgmRecord.glucose.length
                        }}
                        days)
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tips">
                <div class="border"></div>
                <div class="label">{{ $t("GluEvent") }}</div>
              </div>
              <div class="glu-event">
                <table border="0" cellspacing="0" cellpadding="4">
                  <tr>
                    <th></th>
                    <th>&lt;2.8mmol/L</th>
                    <th>&lt;3.3mmol/L</th>
                    <th>&lt;3.9mmol/L</th>
                    <th>&gt;10mmol/L</th>
                    <th>&gt;13.9mmol/L</th>
                    <th>&gt;22.2mmol/L</th>
                  </tr>

                  <tr>
                    <td>{{ $t("AvgHoursPerDay") }}</td>
                    <td>{{ tirVal([0]).hour }}</td>
                    <td>{{ tirVal([0, 1]).hour }}</td>
                    <td>{{ tirVal([0, 1, 2]).hour }}</td>
                    <td>{{ tirVal([5, 6, 7]).hour }}</td>
                    <td>{{ tirVal([6, 7]).hour }}</td>
                    <td>{{ tirVal([7]).hour }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("MeanEpisodesDay") }}</td>
                    <td>
                      {{
                        formatterIndicator(
                          dataFirstPage(cgmRecord.lbgd28).times
                        )
                      }}
                    </td>
                    <td>
                      {{
                        formatterIndicator(
                          dataFirstPage(cgmRecord.lbgd33).times
                        )
                      }}
                    </td>
                    <td>
                      {{
                        formatterIndicator(
                          dataFirstPage(cgmRecord.lbgd39).times
                        )
                      }}
                    </td>
                    <td>
                      {{
                        formatterIndicator(
                          dataFirstPage(cgmRecord.lbgd100).times
                        )
                      }}
                    </td>
                    <td>
                      {{
                        formatterIndicator(
                          dataFirstPage(cgmRecord.lbgd139).times
                        )
                      }}
                    </td>
                    <td>
                      {{
                        formatterIndicator(
                          dataFirstPage(cgmRecord.lbgd222).times
                        )
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("MeanDurationHours") }}</td>
                    <td>
                      {{
                        formatterIndicator(
                          dataFirstPage(cgmRecord.lbgd28).times
                        )
                      }}
                    </td>
                    <td>
                      {{
                        formatterIndicator(
                          dataFirstPage(cgmRecord.lbgd33).meanDuration
                        )
                      }}
                    </td>
                    <td>
                      {{
                        formatterIndicator(
                          dataFirstPage(cgmRecord.lbgd39).meanDuration
                        )
                      }}
                    </td>
                    <td>
                      {{
                        formatterIndicator(
                          dataFirstPage(cgmRecord.lbgd100).meanDuration
                        )
                      }}
                    </td>
                    <td>
                      {{
                        formatterIndicator(
                          dataFirstPage(cgmRecord.lbgd139).meanDuration
                        )
                      }}
                    </td>
                    <td>
                      {{
                        formatterIndicator(
                          dataFirstPage(cgmRecord.lbgd222).meanDuration
                        )
                      }}
                    </td>
                  </tr>
                </table>
              </div>
              <div style="text-align: left">{{ $t("GluEventTip") }}</div>
              <div class="tips">
                <div class="border"></div>
                <div class="label">{{ $t("DailyStatistics") }}</div>
              </div>
              <div class="glu-overview">
                <table border="0" cellspacing="0" cellpadding="4">
                  <tr>
                    <th>{{ $t("Index") }}</th>
                    <th>{{ $t("ReferenceValue") }}(24h)</th>
                    <th v-for="(item, index) in cgmRecord.glucose" :key="index">
                      {{ dateFormatter(index, "MM/DD") }}
                    </th>
                  </tr>
                  <tr
                    v-for="(item, pIndex) in new Array(13).fill(0)"
                    :key="pIndex"
                  >
                    <td style="width: 22%">
                      {{ getDayDataOverview(pIndex)[0] }}
                    </td>
                    <td style="width: 10%">
                      {{ getDayDataOverview(pIndex)[1] }}
                    </td>
                    <td
                      v-for="(item, index) in cgmRecord.glucose"
                      :key="index"
                      :class="{ 'bar-item': pIndex === 12 }"
                    >
                      <span v-if="pIndex < 12">{{
                        getDayDataOverviewContent(pIndex, index)
                      }}</span>
                      <div v-show="isShowGluPercent(index)" class="highBar">
                        <div
                          class="normalBar"
                          :style="{
                            height: getDayDataOverviewContent(11, index) + '%',
                          }"
                        ></div>
                        <div
                          class="lowBar"
                          :style="{
                            height: getDayDataOverviewContent(9, index) + '%',
                          }"
                        ></div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="last-tip">
                <div class="day-glu-overview">
                  <div class="p1">
                    <div class="block3"></div>
                    <div class="tip-label">{{ $t("High") }}(>10.0mmol/L)</div>
                  </div>
                  <div class="p2">
                    <div class="block1"></div>
                    <div class="tip-label">
                      {{ $t("Moderate") }}(3.9-10.0mmol/L)
                    </div>
                  </div>
                  <div class="p3">
                    <div class="block2"></div>
                    <div class="tip-label">{{ $t("Low") }}(＜3.9mmol/L)</div>
                  </div>
                </div>
                <div class="gist">
                  * {{ $t("StatisticsExTitleStart") }}
                  <i v-html="$t('StatisticsRef')"></i>
                  {{ $t("StatisticsExTitleEnd") }}
                  <br />
                  {{
                    $t("StatisticsExIndex", [
                      cgmRecord.glucose.length * 288,
                      formatterIndicator(dataFirstPage(cgmRecord.mbg), true),
                      formatterIndicator(dataFirstPage(cgmRecord.sdbg), true),
                      formatterIndicator(dataFirstPage(cgmRecord.cv)),
                      formatterIndicator(dataFirstPage(cgmRecord.maxbg)),
                      formatterIndicator(dataFirstPage(cgmRecord.minbg)),
                      tirTimeRange.n.value,
                      tirVal([4, 5, 6, 7]).value,
                      tirVal([5, 6, 7]).value,
                      tirVal([6, 7]).value,
                      tirVal([0, 1, 2]).value,
                      tirVal([0]).value,
                    ])
                  }}
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <div class="page-footer-space"></div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>
<script>
import {
  getAgpOptions,
  getGluOptions,
  getMiniGluOptions,
  getMultiGluChartOptions,
  getPentagonChartOptions,
  getAreaChartOptions,
  getDayPentagonChartOptions,
} from "@/util/chart";
import { cgmApi } from "@/util/api";
import dayjs from "dayjs";
import arrow from "@/components/Arrow";

export default {
  name: "AidexEu",
  components: {
    arrow,
  },
  mounted() {
    window.onbeforeprint = () => {
      window.history.pushState("", "", "/");
    };
    window.onafterprint = () => window.history.back();
    window.addEventListener("message", (res) => {
      if (res.data.type === "print") {
        window.print();
      }
    });
    if (window.navigator.userAgent.indexOf("Firefox") > -1) {
      const pentagonTable =
        document.getElementsByClassName("pentagon-table")[0];
      const gluTable = document.getElementsByClassName("day-glu-info")[0];
      gluTable.setAttribute("style", "font-size: 10px");
      pentagonTable.setAttribute(
        "style",
        "transform: scale(0.9);transform-origin: 0 0;width: 110%;margin-bottom: -25px;font-size:11px;"
      );
    }
    this.loadingInstance = this.$loading({ fullscreen: true });
    this.baseInfo = this.$route.query;
    if (!this.baseInfo.id || !this.baseInfo.start || !this.baseInfo.end) {
      this.$message.error("missing query parameters");
      return;
    }
    if (this.baseInfo.name) {
      document.title = this.baseInfo.name + "'s report";
    }
    if (this.baseInfo.phone) {
      this.baseInfo.phone = this.baseInfo.phone.replace(
        /^(\d{3})\d{4}(\d{4})$/,
        "$1****$2"
      );
    }
    cgmApi
      .fetchAidexRecord({
        hyper: 10,
        hypo: 3.9,
        start_time: this.baseInfo.start,
        end_time: this.baseInfo.end,
        userId: this.baseInfo.id,
      })
      .then((res) => {
        const { data, code, msg } = res.data;
        if (code !== 1) {
          this.$message.error(msg);
          return;
        }
        if (data && Object.keys(data).length !== 0) {
          this.cgmRecord = data;
          this.startDate = dayjs(this.cgmRecord.beginDate).format("MM/DD/YYYY");
          this.endDate = dayjs(this.cgmRecord.endDate).format("MM/DD/YYYY");
          this.startDateTime = dayjs(this.cgmRecord.beginDate).format("MM.DD");
          this.endDateTime = dayjs(this.cgmRecord.endDate).format("MM.DD");
        }
        this.$nextTick(() => {
          this.drawAgpChart();
          this.drawGluChart();
          this.drawMiniGluChart();
          this.drawMultiGluChart();
          this.drawPentagonChart();
          this.drawAreaChart();
          this.drawDayPentagonChart();
          this.loadingInstance.close();
        });
      });
  },
  methods: {
    isEmptyGlucose(index) {
      if (!this.cgmRecord.glucose || !this.cgmRecord.glucose[index]) {
        return false;
      }
      return this.cgmRecord.glucose[index].filter((g) => !isNaN(g)).length === 0
        ? true
        : false;
    },
    isShowGluPercent(index) {
      if (!this.cgmRecord.glucose || !this.cgmRecord.glucose[index]) {
        return false;
      }
      return this.cgmRecord.glucose[index].filter((g) => !isNaN(g)).length > 14
        ? true
        : false;
    },
    getToday(format) {
      format = format ? format : "MM/DD/YYYY HH:mm:ss";
      return dayjs().format(format);
    },
    getPercentStyle() {
      let hv = this.tirTimeRange.h.value;
      let nv = this.tirTimeRange.n.value;
      let lv = this.tirTimeRange.l.value;
      const filterZero = (val, height) => (val === 0 ? 0 : height);
      if (hv >= 96) {
        return {
          n: { height: filterZero(nv, lv === 0 ? "2%" : "4%") },
          l: { height: filterZero(lv, "2%") },
        };
      } else if (lv >= 96) {
        return {
          n: { height: filterZero(nv, hv === 0 ? "100%" : "98%") },
          l: { height: lv === 100 ? "100%" : "96%" },
        };
      } else if (Number(lv) + Number(nv) >= 96) {
        return {
          n: { height: filterZero(nv, hv === 0 ? "100%" : "98%") },
          l: { height: filterZero(lv, `${lv < 2 ? 2 : lv}%`) },
        };
      }
      return {
        n: {
          height: filterZero(nv, `${Number(nv) + Number(lv)}%`),
        },
        l: { height: filterZero(lv, `${lv < 2 ? 2 : lv}%`) },
      };
    },

    getPercentLabelStyle() {
      const _hv = this.tirTimeRange.h.value;
      const _nv = this.tirTimeRange.n.value;
      const _lv = this.tirTimeRange.l.value;
      const displayStyle = {
        h: _hv === 0 ? "none" : "block",
        n: _nv === 0 ? "none" : "block",
        l: _lv === 0 ? "none" : "block",
      };
      if (_hv >= 96) {
        return {
          h: { top: `${178 * 48 * 0.01 - 9}px`, display: displayStyle.h },
          n: {
            top: `${_lv === 0 ? 178 * 100 * 0.01 - 9 : 178 * 90 * 0.01 - 9}px`,
            display: displayStyle.n,
          },
          l: { top: `${178 * 100 * 0.01 - 9}px`, display: displayStyle.l },
        };
      } else if (_nv >= 96) {
        return {
          h: { top: `${178 * 1 * 0.01 - 9}px`, display: displayStyle.h },
          n: { top: `${178 * 50 * 0.01 - 9}px`, display: displayStyle.n },
          l: { top: `${178 * 100 * 0.01 - 9}px`, display: displayStyle.l },
        };
      } else if (_lv >= 96) {
        return {
          h: { top: `${178 * 0 * 0.01 - 9}px`, display: displayStyle.h },
          n: {
            top: `${_hv === 0 ? 178 * 0 * 0.01 - 9 : 178 * 10 * 0.01 - 9}px`,
            display: displayStyle.n,
          },
          l: { top: `${178 * 52 * 0.01 - 9}px`, display: displayStyle.l },
        };
      }
      let hv = 178 * _hv * 0.5 * 0.01 - 9;
      let nv = 178 * (Number(_nv) / 2 + Number(_hv)) * 0.01 - 9;
      let lv = 178 * (100 - Number(_lv) + Number(_lv) / 2) * 0.01 - 9;
      if (nv - hv < 10) {
        nv += 10;
      }
      if (lv - nv < 10) {
        nv -= 10;
      }
      return {
        h: {
          top: `${hv}px`,
          display: displayStyle.h,
        },
        n: {
          top: `${nv}px`,
          display: displayStyle.n,
        },
        l: {
          top: `${lv}px`,
          display: displayStyle.l,
        },
      };
    },

    formatterIndicator(value, isConvertUnit) {
      // return isConvertUnit
      //   ? (Number(value) / 18).toFixed(2)
      //   : Number(value).toFixed(2);
      if (isNaN(value)) {
        return "";
      }
      return Number.isInteger(Number(value))
        ? Number(value)
        : Number(value).toFixed(2);
    },
    dataFirstPage(data = []) {
      if (data.length === 0) {
        return 0;
      }
      return data[this.cgmRecord.glucose.length];
    },
    drawAgpChart() {
      const agpChart = this.$echarts.init(document.getElementById("agp"));
      agpChart.setOption(
        getAgpOptions(
          this.cgmRecord.dailyPrctile10,
          this.cgmRecord.dailyPrctile25,
          this.cgmRecord.dailyPrctile50,
          this.cgmRecord.dailyPrctile75,
          this.cgmRecord.dailyPrctile90,
          true
        )
      );
    },
    drawMiniGluChart() {
      this.cgmRecord.glucose.forEach((item, index) => {
        const gluChart = this.$echarts.init(
          document.getElementById(`glu_mini_chart_${index}`)
        );
        gluChart.setOption(
          getMiniGluOptions(item, index, this.cgmRecord.beginDate)
        );
      });
    },
    drawGluChart() {
      this.cgmRecord.glucose.forEach((item, index) => {
        const gluChart = this.$echarts.init(
          document.getElementById(`glu_chart_${index}`)
        );
        gluChart.setOption(
          getGluOptions(
            item,
            index,
            this.cgmRecord.beginDate,
            this.cgmRecord.eventMap,
            true
          )
        );
      });
    },
    drawMultiGluChart() {
      const multiChart = this.$echarts.init(
        document.getElementById("multi_glu")
      );
      multiChart.setOption(
        getMultiGluChartOptions(
          this.cgmRecord.glucose,
          this.cgmRecord.beginDate
        )
      );
    },
    drawPentagonChart() {
      const chart = this.$echarts.init(document.getElementById("pentagon"));
      chart.setOption(
        getPentagonChartOptions(this.dataFirstPage(this.cgmRecord.pentagon))
      );
    },
    drawAreaChart() {
      const chart = this.$echarts.init(document.getElementById("area"));
      chart.setOption(
        getAreaChartOptions(
          this.cgmRecord.pentagon,
          this.cgmRecord.beginDate,
          this.cgmRecord.glucose.length
        )
      );
    },
    drawDayPentagonChart() {
      this.cgmRecord.pentagon.forEach((item, index) => {
        if (
          index < this.cgmRecord.glucose.length &&
          !this.isEmptyGlucose(index)
        ) {
          const chart = this.$echarts.init(
            document.getElementById(`day_pentagon_chart_${index}`)
          );
          chart.setOption(getDayPentagonChartOptions(item));
        }
      });
    },
    dateFormatter(index, format) {
      if (!this.cgmRecord.beginDate || !this.cgmRecord.endDate) {
        return "";
      }
      return dayjs(this.cgmRecord.beginDate).add(index, "day").format(format);
    },
    enDateFormatter(index, format) {
      const enMonth = [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEPT",
        "OCT",
        "NOV",
        "DEC",
      ];
      if (!this.cgmRecord.beginDate || !this.cgmRecord.endDate) {
        return "";
      }
      const date = dayjs(this.cgmRecord.beginDate)
        .add(index, "day")
        .format(format)
        .split("/");
      return [enMonth[date[0] - 1], date[1]];
    },
    bindGluItemChartId(index) {
      return "glu_chart_" + index;
    },
    bindMiniGluItemChartId(index) {
      return "glu_mini_chart_" + index;
    },
    bindPentagonId(index) {
      return "day_pentagon_chart_" + index;
    },
    getPentagonTableItem(index) {
      return [
        [this.$t("DailyBG"), ""],
        [this.$t("TimeOutofrange"), ""],
        [this.$t("Coeofvariance"), ""],
        [this.$t("IntensityHYPO"), "mg/dl*min²"],
        [this.$t("IntensityHYPER"), "mg/dl*min²"],
        [this.$t("MeanBloodGlucose"), "mg/dl"],
        [this.$t("AREA"), "mm²"],
      ][index];
    },
    getPentagonTableTdContent(pIndex, index) {
      const indexMapping = [
        "",
        "tor",
        "cv",
        "intHypo",
        "intHyper",
        "mbg",
        "area",
      ];
      let val = this.cgmRecord.pentagon
        .slice(0, this.cgmRecord.glucose.length)
        .map((c) => c[indexMapping[pIndex]])[index];
      if (isNaN(val) || this.isEmptyGlucose(index)) {
        return "";
      }
      val = Number.isInteger(val) || isNaN(val) ? val : val.toFixed(1);
      if (pIndex === 1) {
        const percent = ((val / 1440) * 100).toFixed();
        val = `${val}\n(${percent}%)`;
      }
      return val;
    },
    getDayDataOverview(index) {
      return [
        [this.$t("NumberofDataPoints")],
        [this.$t("MeanMBG"), "<6.6"],
        [this.$t("StandardDeviation"), "<1.4"],
        [this.$t("StatisticsCV"), ""],
        [this.$t("StatisticsHigh"), ""],
        [this.$t("StatisticsLowest"), ""],
        [this.$t("StatisticsPercentage") + "≥13.9 mmol/L", ""],
        [this.$t("StatisticsPercentage") + "≥10.0 mmol/L", ""],
        [this.$t("StatisticsPercentage") + "≥7.8 mmol/L", "<4H(17%)"],
        [this.$t("StatisticsPercentage") + "≤3.9 mmol/L", "<3H(12%)"],
        [this.$t("StatisticsPercentage") + "≤2.8 mmol/L", ""],
        [this.$t("StatisticsPercentageWithin") + "3.9mmol/L to 10 mmol/L", ""],
        [this.$t("TimeinRange"), ""],
      ][index];
    },
    getDayDataOverviewContent(pIndex, index) {
      if (pIndex === 0) {
        return this.formatterIndicator(this.cgmRecord.num[index]);
      } else if (!this.isShowGluPercent(index)) {
        return "";
      } else if (pIndex === 1) {
        return this.formatterIndicator(this.cgmRecord.mbg[index]);
      } else if (pIndex === 2) {
        return this.formatterIndicator(this.cgmRecord.sdbg[index]);
      } else if (pIndex === 3) {
        return this.formatterIndicator(this.cgmRecord.cv[index]);
      } else if (pIndex === 4) {
        return this.formatterIndicator(this.cgmRecord.maxbg[index]);
      } else if (pIndex === 5) {
        return this.formatterIndicator(this.cgmRecord.minbg[index]);
      } else if (pIndex === 6) {
        return this.formatterIndicator(
          this.cgmRecord.tir.tir.map((c) => c[7] + c[6])[index]
        );
      } else if (pIndex === 7) {
        return this.formatterIndicator(
          this.cgmRecord.tir.tir.map((c) => c[7] + c[6] + c[5])[index]
        );
      } else if (pIndex === 8) {
        return this.formatterIndicator(
          this.cgmRecord.tir.tir.map((c) => c[7] + c[6] + c[5] + c[4])[index]
        );
      } else if (pIndex === 9) {
        return this.formatterIndicator(
          this.cgmRecord.tir.tir.map((c) => c[0] + c[1] + c[2])[index]
        );
      } else if (pIndex === 10) {
        return this.formatterIndicator(
          this.cgmRecord.tir.tir.map((c) => c[0])[index]
        );
      } else if (pIndex === 11) {
        return this.formatterIndicator(
          this.cgmRecord.tir.tir.map((c) => c[3] + c[4])[index]
        );
      }
    },
    tirVal(arr) {
      let sum = 0;
      arr.forEach(
        (index) => (sum += this.dataFirstPage(this.cgmRecord.tir.tir)[index])
      );
      let _timeNoFormatter = 24 * sum * 0.01;
      let time = Number.isInteger(_timeNoFormatter)
        ? _timeNoFormatter
        : _timeNoFormatter.toFixed(1);
      let hour = Math.floor(time);
      let min = (time - hour) * 60;
      return {
        value: sum.toFixed(2),
        time: `${hour}h${min}分`,
        hour: isNaN(time) ? "" : time,
      };
    },
    getEventMapByOffsetIndex(index) {
      if (
        !this.cgmRecord.eventMap ||
        Object.keys(this.cgmRecord.eventMap).length === 0
      ) {
        return [];
      }
      return (
        this.cgmRecord.eventMap[this.dateFormatter(index, "YYYY-MM-DD")].slice(
          0,
          10
        ) || []
      );
    },
    getEventLabel(item) {
      let unit = "mg";
      if (item.eventType === 1) {
        unit = "g";
      } else if (item.eventType === 2) {
        unit = "mins";
      } else if (item.eventType === 3) {
        unit = "U";
      }
      return (
        item.eventTime.slice(11, 16) +
        " " +
        item.eventName +
        " " +
        item.eventData +
        unit
      );
    },
  },
  computed: {
    patientAge() {
      if (this.baseInfo.birthday) {
        return dayjs().diff(dayjs(this.baseInfo.birthday), "year");
      }
      return "--";
    },
    tirTimeRange() {
      if (!this.cgmRecord.tir || !this.cgmRecord.tir.tir) {
        return {
          h: { value: 0, time: 0 },
          n: { value: 0, time: 0 },
          l: { value: 0, time: 0 },
        };
      }
      const tirVal = this.dataFirstPage(this.cgmRecord.tir.tir);
      const hVal = tirVal[5] + tirVal[6] + tirVal[7];
      const nVal = tirVal[3] + tirVal[4];
      const lVal = tirVal[0] + tirVal[1] + tirVal[2];
      const hTime = 24 * hVal * 0.01;
      const nTime = 24 * nVal * 0.01;
      const lTime = 24 * lVal * 0.01;
      return {
        h: {
          value: Number.isInteger(hVal) ? hVal : hVal.toFixed(1),
          time: Number.isInteger(hTime) ? hTime : hTime.toFixed(1),
        },
        n: {
          value: Number.isInteger(nVal) ? nVal : nVal.toFixed(1),
          time: Number.isInteger(nTime) ? nTime : nTime.toFixed(1),
        },
        l: {
          value: Number.isInteger(lVal) ? lVal : lVal.toFixed(1),
          time: Number.isInteger(lTime) ? lTime : lTime.toFixed(1),
        },
      };
    },
    triangleStyle() {
      const obj = {
        width: 0,
        height: 0,
        "border-top": "20px solid transparent",

        "border-bottom": "20px solid transparent",
        position: "absolute",
        left: "60px",
        "z-index": 12,
      };
      const val = +this.formatterIndicator(this.cgmRecord.lbgi);
      if (val <= 1.1) {
        obj.top = "120px";
        obj["border-left"] = "10px solid #61a311";
      } else if (val <= 2.5) {
        obj.top = "80px";
        obj["border-left"] = "10px solid #1da9ed";
      } else if (val <= 5) {
        obj.top = "40px";
        obj["border-left"] = "10px solid #f38f20";
      } else {
        obj.top = "0px";
        obj["border-left"] = "10px solid #d61a00";
      }
      return obj;
    },
    lbgiHeight() {
      const val = +this.formatterIndicator(this.cgmRecord.lbgi);
      if (val <= 1.1) {
        return { top: "128px" };
      } else if (val <= 2.5) {
        return { top: "88px" };
      } else if (val <= 5) {
        return { top: "48px" };
      } else {
        return { top: "8px" };
      }
    },
  },
  data() {
    return {
      loadingInstance: null,
      baseInfo: {},
      startDate: "",
      endDate: "",
      startDateTime: "",
      endDateTime: "",
      serialNo: +new Date(),
      cgmRecord: {
        eventMap: {},
        mage: [],
        num: [],
        mbg: [],
        maxbg: [],
        minbg: [],
        sdbg: [],
        pentagon: [],
        grade: {
          grade: 0,
        },
        glucose: [],
        tir: {
          tir: [],
        },
        lbgd28: [],
        lbgd39: [],
        lbgd100: [],
        lbgd139: [],
        lbgd222: [],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
@page {
  size: A4;
  margin: 0 0 1cm 0;
}

.page-header-space {
  height: 2cm;
}
.page-footer-space {
  height: 0;
}

.panel {
  -webkit-print-color-adjust: exact;
  -moz-print-color-adjust: exact;
  color-adjust: exact;
  font-family: "Microsoft YaHei";
}
.container {
  -webkit-print-color-adjust: exact;
  -moz-print-color-adjust: exact;
  color-adjust: exact;
  // box-shadow: 0 2px 12px 0 #d9d9d9;
  width: 21cm;
  min-height: 29.7cm;
  margin: 0 auto;
  border-radius: 2px;
  background-color: #fff;
  text-align: center;
  padding: 0 1cm;
  font-size: 12px;

  .header {
    color: #1f3c90;
    font-size: 36px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      display: block;
      width: 45px;
      height: 45px;
      margin-right: 10px;
    }
  }

  .new-page {
    page-break-before: always;
  }
  .aviod-new-page {
    page-break-inside: avoid;
    overflow: auto;
  }

  .tip-panel {
    margin: 30px 0;
    border-bottom: 1px solid #f0f0f0;
    position: relative;
    .label-panel {
      padding: 0 20px;
      line-height: 30px;
      background-color: #137eb2;
      border-radius: 50px;
      color: white;
      position: absolute;
      bottom: -15px;
      // left: 15px;
      font-size: 14px;
    }
  }

  .tips {
    page-break-inside: avoid;
    font-size: 14px;
    font-weight: 500;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    line-height: 26px;
    position: relative;
    left: -22px;
    .border {
      width: 10px;
      margin-right: 10px;
      background-color: #1f3c90;
    }
    .label {
      color: white;
      background-color: #1f3c90;
      border-radius: 5px;
      padding: 0 20px;
    }
    .comment {
      margin-left: 10px;
      font-weight: 400;
    }
  }

  .base-info {
    .info {
      width: 100%;
      display: flex;
      flex-direction: row;
      .person-info {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        .info-item {
          width: 50%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          margin-top: 5px;
          font-size: 12px;

          > div:first-child {
            width: 160px;
            text-align: left;
          }
        }
      }
      .device-info {
        width: 50%;
        .device-tip {
          color: #1a9796;
          text-align: left;
        }
      }
    }
  }

  #multi_glu {
    width: 100%;
    zoom: 0.9;
    height: 320px;
  }

  .agp-info {
    #agp {
      width: 110%;
      height: 300px;
      transform: scale(0.9);
      transform-origin: 0 0;
      margin-bottom: -40px;
    }
    .legend {
      display: flex;
      flex-direction: row;
      justify-content: center;
      .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 15px;

        .b1 {
          width: 30px;
          height: 2px;
          background-color: #05367f;
          margin-right: 5px;
        }
        .b2 {
          width: 30px;
          height: 15px;
          background-color: #7fa2d4;
          margin-right: 5px;
        }
        .b3 {
          width: 30px;
          height: 15px;
          background-color: #d2e1f2;
          margin-right: 5px;
        }
        .b4 {
          width: 30px;
          height: 8px;
          margin-right: 5px;
          border-top: 2px dashed #72b522;
          border-bottom: 2px dashed #72b522;
        }
      }
    }
  }
  .glu-info {
    margin-bottom: -35px;
    .info {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin: 5px 0 0 0;
      justify-content: flex-start;
      .glu-data {
        // zoom: 0.8;
        transform: scale(0.8);
        transform-origin: 0 0;
        position: relative;
        width: 50%;
        height: 200px;
        border: 1px solid #f0f0f0;
        background-color: #f5f5f5;
        border-radius: 10px;
        display: flex;
        flex-direction: row;

        .item-panel {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          flex-wrap: wrap;
          align-items: center;
          position: relative;
          .left {
            width: 33%;
            height: 100%;
          }
          .center {
            width: 33%;
            height: 100%;
            background-color: white;
          }
          .right {
            width: 33%;
            height: 100%;
          }
          .left .center .right {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
          }

          .item {
            height: 100px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            transform: scale(0.82);
            .label-en {
              font-size: 12px;
              color: #1f3c90;
              font-weight: 600;
            }
            .label-ch {
              color: #747474;
              font-size: 12px;
            }
            .label-ref {
              font-size: 12px;
              color: #747474;
            }
            .num {
              font-size: 16px;
              // color: #747474;
              font-weight: 600;
              margin: 2px 0;
              span {
                color: black;
                font-size: 14px;
                margin-left: 5px;
                // font-weight: 500;
              }
            }
          }
        }
        .item-right-panel {
          width: 30%;
          display: flex;
          flex-direction: column;
          align-items: center;
          .label {
            color: #1f3c90;
            font-size: 12px;
            font-weight: 500;
            position: absolute;
          }
          .bar-contianer {
            height: 180px;
            width: 26px;
            margin-top: 10px;
            margin-left: 10px;
            border-radius: 13px;
            background-color: #eaeaea;
            position: relative;

            .p1 {
              position: absolute;
              left: -35px;
              top: 20px;
            }
            .p2 {
              position: absolute;
              left: -35px;
              top: 60px;
            }
            .p3 {
              position: absolute;
              left: -35px;
              top: 100px;
            }
            .p4 {
              position: absolute;
              left: -40px;
              top: 145px;
            }
          }
          .content-contianer {
            position: absolute;
            height: 100px;
            width: 26px;
            border-radius: 0 0 13px 13px;
            background-color: #d61a00;
            bottom: 0;
          }
        }
      }
      .glu-risk {
        width: 20%;
        position: relative;
        left: -60px;
        height: 200px;
        z-index: 1;
        height: 150px;
        .label {
          z-index: 10;
          position: absolute;
          left: 30px;
          top: -9px;
          width: 160px;
          font-weight: 500;
          font-size: 12px;
          color: #1f3c90;
        }
        .red-panel {
          // zoom: 0.7;
          transform: scale(0.8);
          transform: 0 0;
          width: 60px;
          height: 160px;
          background-color: #d61a00;
          position: absolute;
          top: 10px;
          left: 5px;
          z-index: 1;
          span {
            color: white;
            font-size: 18px;
            font-weight: 500;
            position: absolute;
            top: 8px;
            left: 12px;
            z-index: 12;
          }
        }
        .orange-item {
          width: 100%;
          height: 120px;
          background-color: #f38f20;
          position: absolute;
          bottom: 0;
          z-index: 2;
        }
        .blue-item {
          width: 100%;
          height: 80px;
          background-color: #1da9ed;
          position: absolute;
          bottom: 0;
          z-index: 3;
        }
        .green-item {
          width: 100%;
          height: 40px;
          background-color: #61a311;
          position: absolute;
          bottom: 0;
          z-index: 4;
        }
        .risk-indicator {
          transform: scale(0.7);
          transform: 0 0;
          width: 50%;
          height: 100%;
          position: relative;
          left: 100px;
          top: -10px;
          .tip-label {
            font-size: 12px;
            width: 120px;
            text-align: left;
          }
          .green-lg {
            position: absolute;
            display: flex;
            flex-direction: row;
            align-items: center;
            top: 150px;
            left: 20px;
            width: 190px;
            .green-block {
              width: 10px;
              height: 10px;
              margin-right: 10px;
              background-color: #61a311;
            }
          }
          .blue-lg {
            position: absolute;
            display: flex;
            flex-direction: row;
            align-items: center;
            top: 110px;
            left: 20px;
            width: 190px;
            .green-block {
              width: 10px;
              height: 10px;
              margin-right: 10px;
              background-color: #1da9ed;
            }
          }
          .yellow-lg {
            position: absolute;
            display: flex;
            flex-direction: row;
            align-items: center;
            top: 70px;
            left: 20px;
            width: 190px;
            .yellow-block {
              width: 10px;
              height: 10px;
              margin-right: 10px;
              background-color: #f38f20;
            }
          }
          .red-lg {
            position: absolute;
            display: flex;
            flex-direction: row;
            align-items: center;
            top: 30px;
            left: 20px;
            width: 190px;
            .red-block {
              width: 10px;
              height: 10px;
              margin-right: 10px;
              background-color: #d61a00;
            }
          }
        }
      }
      .glu-legend {
        position: relative;
        margin-left: -40px;
        width: 40%;
        display: flex;
        flex-direction: row;
        position: relative;

        .label2 {
          color: #1f3c90;
          font-size: 12px;
          font-weight: 500;
          position: absolute;
          left: 50px;
          top: -8px;
        }
        .left-item {
          // zoom: 0.7;
          transform: scale(0.65);
          transform-origin: 0 0;
          left: -20px;
          width: 50%;
          height: 100%;
          position: relative;
          // top: -10px;
          .yellow {
            position: absolute;
            height: 180px;
            width: 26px;
            // border-radius: 13px;
            top: 45px;
            left: 0;
            background-color: #f38f20;
          }
          .green {
            position: absolute;
            width: 26px;
            // border-radius: 0 0 13px 13px;
            background-color: #61a311;
            bottom: 0;
          }
          .red {
            position: absolute;
            width: 26px;
            // border-radius: 0 0 13px 13px;
            background-color: #d61a00;
            bottom: 0;
          }
          .p1,
          .p2,
          .p3 {
            position: absolute;
            width: 200px;
            text-align: left;
            // color: #666666;
            font-size: 13px;
          }
          .p1 {
            top: 0px;
            left: 30px;
          }
          .p2 {
            top: 90px;
            left: 30px;
          }
          .p3 {
            top: 160px;
            left: 30px;
          }
        }
        .right-item {
          transform: scale(0.7);
          transform-origin: 0 0;
          width: 50%;
          height: 100%;
          position: relative;
          // top: -25px;
          left: -50px;
          top: -10px;
          .tip-label {
            font-size: 12px;
            width: 150px;
            text-align: left;
          }
          .yellow-lg1 {
            position: absolute;
            display: flex;
            flex-direction: row;
            align-items: center;
            top: 60px;
            left: 20px;
            width: 190px;
            .yellow-block {
              width: 10px;
              height: 10px;
              margin-right: 10px;
              background-color: #f38f20;
            }
          }
          .green-lg1 {
            position: absolute;
            display: flex;
            flex-direction: row;
            align-items: center;
            top: 120px;
            left: 20px;
            width: 190px;
            .green-block {
              width: 10px;
              height: 10px;
              margin-right: 10px;
              background-color: #61a311;
            }
          }
          .red-lg1 {
            position: absolute;
            display: flex;
            flex-direction: row;
            align-items: center;
            top: 180px;
            left: 20px;
            width: 190px;
            .red-block {
              width: 10px;
              height: 10px;
              margin-right: 10px;
              background-color: #d61a00;
            }
          }
        }
      }
    }
  }
  .day-glu-info {
    margin-top: 5px;
    display: flex;
    justify-content: center;
    zoom: 0.7;
    font-size: 12px;
    table {
      width: 100%;
      margin-bottom: 10px;
      // border-color: #666666;
      // border: 1px solid #d1d1d1;
      th {
        background-color: #f0f0f0;
        // font-size: 14px;
        font-weight: 400;
        border-right: 1px solid #d1d1d1;
        border-top: 1px solid #d1d1d1;
        &:first-child {
          border-left: 1px solid #d1d1d1;
        }
      }
      td {
        border-right: 1px solid #d1d1d1;
        border-bottom: 1px solid #d1d1d1;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(4) {
          text-align: left;
          padding: 5px 0 5px 10px;
        }
        &:first-child {
          border-left: 1px solid #d1d1d1;
        }
      }
      tr:nth-last-child(even) {
        background-color: #f0f0f0;
      }
      tr:last-child {
        td {
          border-bottom: 1px solid #d1d1d1;
        }
      }
      .ghost {
        border-top: none;
        border-bottom: none;
        background-color: white;
      }
    }
  }

  .pentagon-panel {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    font-size: 14px;
    margin-bottom: -120px;
    .average {
      font-weight: 600;
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      #pentagon {
        width: 100%;
        height: 300px;
        // zoom: 0.6;
        transform: scale(0.6);
        transform-origin: 0 0;
        position: relative;
        left: -5px;
      }
    }
    .area {
      width: 50%;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      align-items: center;
      > div:first-child {
        position: relative;
        right: 30px;
      }
      #area {
        width: 200%;
        height: 300px;
        // zoom: 0.6;
        transform: scale(0.6);
        transform-origin: 0 0;
        position: relative;
        left: 110px;
      }
    }
  }
  .day-pentagon {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    > div:first-child {
      width: 10%;
      display: flex;
      font-size: 12px;
      flex-direction: column;
      justify-content: center;
      font-weight: 500;
    }

    .day-pentagon-item {
      width: 9%;
      height: 60px;
      position: relative;
      .chart {
        width: 100%;
        height: 100%;
      }
    }
  }
  .pentagon-table {
    width: 100%;
    font-size: 12px;
    zoom: 0.8;
    .chart {
      margin: 0 auto;
      width: 45px;
      height: 45px;
    }
    table {
      width: 100%;
      margin-bottom: 10px;
      th {
        // background-color: #f0f0f0;
        // font-size: 14px;
        font-weight: 400;
        border-right: 1px solid #d1d1d1;
        border-bottom: 1px solid #d1d1d1;
        border-top: 1px solid #d1d1d1;
        &:first-child {
          border-left: 1px solid #d1d1d1;
        }
      }
      td {
        border-right: 1px solid #d1d1d1;
        border-bottom: 1px solid #d1d1d1;
        &:first-child {
          border-left: 1px solid #d1d1d1;
          > span:first-child {
            font-weight: 500;
          }
        }
      }
      tr:nth-last-child(even) {
        background-color: #f0f0f0;
      }
      tr:last-child {
        td {
          border-bottom: 1px solid #d1d1d1;
        }
      }
    }
  }
  .annotation {
    background-color: #f2f2f2;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 10px;
    font-size: 10px;
    border: 1px solid #d7d7d7;
    font-size: 11px;
    transform: scale(0.9);
    transform-origin: 0 0;
    width: 108%;
    margin-bottom: -30px;
    > div {
      line-height: 18px;
    }
  }

  .mini-glu-panel {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    .mini-day-glu-item {
      width: 18.2%;
      height: 60px;
      border-radius: 10px;
      box-shadow: 0 2px 2px 0 #d9d9d9;
      margin: 10px 10px 0 0;
      border: 1px solid #d9d9d9;
      position: relative;
      .date {
        position: absolute;
        right: 3px;
        top: 0;
        font-size: 12px;
        z-index: 100;
      }
      .chart {
        width: 100%;
        height: 100%;
      }
    }
  }
  .day-glu-item {
    // zoom: 0.85;
    transform: scale(0.85);
    transform-origin: 0 0;
    height: 285px;
    width: 118%;
    display: flex;
    flex-direction: column;
    background-color: #f7f9fc;
    // margin: 5px 0;
    // margin-top: 20px;
    margin-bottom: -24px;
    .chart-panel {
      margin-top: 5px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      .date {
        height: 130px;
        width: 12px;
        word-break: break-all;
        border-radius: 15px;
        font-size: 16px;
        display: flex;
        align-items: center;
        margin-left: 15px;
        padding: 0 5px;
        display: flex;
        flex-direction: column;

        > div:first-child {
          line-height: 16px;
        }

        > div:last-child {
          word-break: normal;
        }
      }
      .chart {
        width: 100%;
        height: 200px;
      }
    }
    .event-panel {
      padding: 0 25px;
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      img {
        width: 20px;
        height: 20px;
        margin-right: 2px;
      }
      div {
        margin: 5px 0 5px 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .event-label {
          width: 140px;
          text-align: left;
          margin-left: 1px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .glu-event {
    margin-top: 10px;
    transform: scale(0.9);
    transform-origin: 0 0;
    width: 111%;
    margin-bottom: -10px;
    table {
      width: 100%;
      th {
        background-color: #f0f0f0;
        font-size: 14px;
        font-weight: 400;
        border-right: 1px solid #d1d1d1;
        border-bottom: 1px solid #d1d1d1;
        border-top: 1px solid #d1d1d1;
        &:first-child {
          border-left: 1px solid #d1d1d1;
        }
      }
      td {
        border-right: 1px solid #d1d1d1;
        border-bottom: 1px solid #d1d1d1;
        &:first-child {
          border-left: 1px solid #d1d1d1;
          text-align: left;
          > span:first-child {
            font-weight: 500;
          }
        }
      }
      tr:last-child {
        td {
          border-bottom: 1px solid #d1d1d1;
        }
      }
    }
  }
  .glu-overview {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // transform: scale(0.9);
    // transform-origin: 0 0;
    // width: 111%;
    // margin-bottom: -56px;
    width: 100%;
    zoom: 0.9;
    table {
      width: 100%;
      // margin-bottom: 10px;
      // zoom: 0.9;
      th {
        // background-color: #f0f0f0;
        // font-size: 14px;
        font-weight: 400;
        border-right: 1px solid #d1d1d1;
        border-bottom: 1px solid #d1d1d1;
        border-top: 1px solid #d1d1d1;
        &:first-child {
          border-left: 1px solid #d1d1d1;
        }
      }
      td {
        border-right: 1px solid #d1d1d1;
        border-bottom: 1px solid #d1d1d1;
        &:first-child {
          border-left: 1px solid #d1d1d1;
          text-align: left;
          > span:first-child {
            font-weight: 500;
          }
        }
      }
      tr:nth-last-child(odd) {
        background-color: #f0f0f0;
      }
      tr:last-child {
        td {
          border-bottom: 1px solid #d1d1d1;
        }
      }
      .bar-item {
        width: 25px;
        .highBar {
          height: 80px;
          max-width: 20px;
          background-color: #f99500;
          // border-radius: 10px;
          margin: 0 auto;
          position: relative;
          .normalBar {
            width: 100%;
            // border-radius: 0 0 10px 10px;
            height: 30%;
            position: absolute;
            bottom: 0;
            background-color: #61a311;
          }
          .lowBar {
            width: 100%;
            // border-radius: 0 0 10px 10px;
            height: 10%;
            position: absolute;
            bottom: 0;
            background-color: #d61a00;
          }
        }
      }
    }
  }

  .last-tip {
    display: flex;
    flex-direction: column;
    border: 1px solid #d1d1d1;
    padding: 10px;
    border-top: none;
    // position: relative;
    // top: -10px;
    height: 110px;
    zoom: 0.75;
    .day-glu-overview {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 5px;
      .block1 {
        width: 10px;
        height: 10px;
        background-color: #61a311;
        margin-right: 10px;
      }
      .block2 {
        width: 10px;
        height: 10px;
        background-color: #d61a00;
        margin-right: 10px;
      }
      .block3 {
        width: 10px;
        height: 10px;
        background-color: #f99500;
        margin-right: 10px;
      }

      .p1,
      .p2,
      .p3 {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 12px;
        text-align: left;
        margin-right: 20px;
      }
    }

    .gist {
      text-align: left;
      font-size: 12px;
      margin-top: 10px;
      > i {
        font-weight: 600;
      }
    }
  }

  .signature {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 3cm;
    .line {
      width: 3cm;
      border-bottom: 1px solid;
    }
  }

  .divider {
    // zoom: 0.7;
    height: 152px;
    width: 2px;
    border-left: 1px dashed;
    right: 60px;
    top: 10px;
    position: absolute;
  }

  ::v-deep .el-divider {
    height: 180px;
    right: 90px;
    top: 20px;
    position: absolute;
  }

  .right {
    left: 64%;
  }

  @media print {
    .base-info {
      zoom: 0.9;
    }
    .annotation {
      zoom: 0.9;
    }
  }
}

.main-content {
  margin: 0 auto;
  box-shadow: 0 2px 12px 0 #d9d9d9;
}

#page-header {
  position: fixed;
  height: 1.5cm;
  top: 0;
  width: 100%;
  border-bottom: 1px dashed #f2f2f2;
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 5px;
  img {
    display: block;
    width: 90px;
    height: 45px;
    // margin-left: 40px;
  }
  .info-one {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
    .title {
      color: #1f3c90;
      font-size: 20px;
      position: relative;
      left: 15px;
    }
    .name-panel {
      font-size: 12px;
      display: inline-flex;
      justify-content: flex-start;
    }
  }
  .info-two {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    width: 50%;
    .left-info,
    .right-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      div {
        margin-top: 10px;
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
  .divider {
    height: 1.2cm;
    width: 2px;
    border-left: 1px dashed #f2f2f2;
    margin: 0 10px 0 24px;
  }
}
#page-footer {
  position: fixed;
  height: 0;
  bottom: 0;
  width: 100%;
  display: none;
}

@media print {
  #page-header,
  #page-footer {
    display: flex;
  }
  body {
    counter-reset: page;
    background-color: #fff;
    margin: 0;
  }

  // #page-footer:after {
  //   counter-increment: page;
  //   content: "Page " counter(page);
  // }

  .main-content {
    box-shadow: none;
  }
}
</style>
