<template>
  <svg
    t="1638154573008"
    class="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="8036"
    width="32"
    height="32"
    :class="{ down: direction === 'down', up: direction === 'up' }"
  >
    <path
      d="M470.016 852.992V278.976l-208 216q-12.992 14.016-31.008 14.016t-31.488-12.512-13.504-30.496 12-32l284-294.016q12.992-14.016 31.488-14.016t31.488 14.016l284.992 296q12 12.992 12 30.016 0 19.008-14.016 32-12.992 12.992-31.008 12.512t-31.008-13.504l-208-216v572.992q0 16.992-12 30.016t-28.992 14.016q-19.008 0.992-32.992-12.512t-14.016-32.512z"
      p-id="8037"
      fill="#d81e06"
    ></path>
  </svg>
</template>
<script>
export default {
  name: "arrow",
  // props: {
  //   value: {
  //     type: Number,
  //     required: true,
  //   },
  //   gt: {
  //     type: Number,
  //     default: null,
  //   },
  //   lt: {
  //     type: Number,
  //     default: null,
  //   },
  // },
  props: ["value", "gt", "lt"],
  computed: {
    direction() {
      let direction = "normal";
      if (!this.gt && this.value >= this.lt) {
        direction = "up";
      } else if (this.value <= this.gt && !this.lt) {
        direction = "down";
      } else if (this.gt && this.lt && this.value < this.gt) {
        direction = "down";
      } else if (this.gt && this.lt && this.value > this.lt) {
        direction = "up";
      }
      return direction;
    },
  },
};
</script>
<style lang="scss" scoped>
.down {
  display: inline-block;
  transform: rotate(180deg);
}

.up {
  display: inline-block;
}

svg {
  display: none;
  width: 13px;
  height: 13px;
}
</style>
