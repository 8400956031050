import axios from "axios";
import qs from "qs";

export const cgmApi = {
  fetchCgmRecord: function (data) {
    data = qs.stringify(data);
    return axios.post(`/user/query_agp`, data, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
  },
  fetchAidexRecord: function (data) {
    data = qs.stringify(data);
    return axios.post(`/user/query_aidex_agp`, data, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
  },
};
